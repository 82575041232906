@import 'Styles/variables';
@import 'Styles/slideout';

.cart-drawer-container {
  .total {
    text-align: left;
    font: bold 18px/24px 'Proxima-Nova';
    letter-spacing: 0px;
    opacity: 1;
    display: block;
  }

  .day {
    color: black;
    margin: 25px;

    .title {
      padding: 0;
      text-align: left;
      font: bold 18px/24px 'Proxima-Nova';
      font-size: large;
      letter-spacing: 0px;
      color: #2d3541;
      opacity: 1;
    }

    .menu-type-group {
      margin: 16px 0;

      .type {
        text-align: left;
        font: 16px/22px 'Source Sans Pro';
        letter-spacing: 0.15px;
        color: @text-primary;
        margin-bottom: 8px;
      }

      .product-category {
        text-align: left;
        font: 16px/22px 'Source Sans Pro';
        letter-spacing: 0.15px;
        color: @text-primary;
        opacity: 1;
        text-transform: uppercase;
      }

      .item {
        margin: 0 0 2px 0;

        .details {
          display: flex;
          justify-content: space-between;
          margin-bottom: var(--linq-spacing-4);

          .name {
            margin-top: 4px;
            text-align: left;
            font: 16px/22px 'Source Sans Pro';
            letter-spacing: 0.15px;
            color: @text-dark;
            opacity: 1;
          }

          .right {
            .price {
              margin-right: 32px;
              text-align: left;
              font: 16px/20px 'Source Sans Pro';
              letter-spacing: 0.29px;
              color: @text-dark;
              opacity: 1;
            }

            button {
              margin-right: 25px;
              transition: transform 200ms ease;
              padding: 2px;

              .icon {
                margin-right: 0;
              }

              &:focus {
                transform: scale(1.25);
                transition: transform 200ms ease;

                .icon {
                  color: @color-primary-light !important;
                }
              }
            }
          }
        }

        .ordering-rules {
          margin-top: 13px;
          background-color: #f8bf00;
          width: 100%;
          padding: 10px 20px;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
          border-radius: 4px;

          .text {
            padding-left: 20px;
          }
        }
      }
    }

    .child-detail {
      display: flex;

      .product-category {
        text-align: right;
        font: 14px/22px 'Source Sans Pro' !important;
        letter-spacing: 0.15px;
        color: @text-primary;
        opacity: 1;
        text-transform: uppercase;
        flex: 0 100px;
        margin-right: var(--linq-spacing-4);
      }

      .name {
        text-align: left;
        font: 16px/22px 'Source Sans Pro';
        letter-spacing: 0.15px;
        color: @text-dark;
        opacity: 1;
        flex: 1;
      }
    }
    .cart-comments {
      margin-bottom: 0px;
      margin-top: var(--linq-spacing-5);
    }
  }
}
