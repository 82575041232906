.terms-of-use {
  padding: 0px !important;
}

.terms-of-use-modal {
  a {
    color: #007ad9 !important;
    text-decoration: none !important;
  }

  button {
    margin-left: auto;
  }
}
