@import 'Styles/variables';

.district-report-table-container {
  margin-top: 18px;

  .item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    font: 20px Source Sans Pro;
    letter-spacing: 0px;
    color: #343434;

    .student-name {
      font-weight: 550;
      padding-right: 5px;
      display: contents;
    }

    svg {
      margin-right: 10px;
    }

    img {
      width: 34px;
      height: 34px;
      margin: 0 10px 0 0;
    }

    &.link {
      color: @button-primary;
      font: 18px Source Sans Pro;
      cursor: pointer;
    }
  }
}
