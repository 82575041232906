.cart-sidebar,
.order-confirmation-sidebar,
.entree-sidebar {
  font-family: var(--linq-font-secondary);

  .section-grouping {
    padding: var(--linq-spacing-6);
  }

  .name-section {
    padding: var(--linq-spacing-6) var(--linq-spacing-6) 0px var(--linq-spacing-6);
  }

  .food-groups,
  .special-meal-accomodations {
    margin-top: var(--linq-spacing-3);
    padding-top: 0;
    padding-bottom: 0;
  }

  .MuiAccordionDetails-root {
    flex-direction: column;
  }

  .menu-type {
    margin-bottom: var(--linq-spacing-4);
    color: var(--linq-color-gray-500);
    text-transform: uppercase;
    font-size: var(--linq-font-size-4);
  }

  .accordion-title {
    color: var(--linq-color-gray-500);
    text-transform: uppercase;
    margin-bottom: var(--linq-spacing-2);
  }

  .student-row {
    display: flex;
    align-items: center;
    margin-bottom: var(--linq-spacing-4);
    margin-left: var(--linq-spacing-2);

    .student-name {
      margin-left: var(--linq-spacing-2);
      font-size: var(--linq-font-size-7);
      flex: 1;
    }
  }

  .oo-drawer-main-section {
    padding: 0;
  }

  .items-expired {
    display: flex;
    align-items: center;
    background: var(--linq-color-warn-100);
    padding: var(--linq-spacing-4);
    border-radius: var(--linq-border-radius-2);

    .text {
      margin-left: var(--linq-spacing-4);
    }
  }

  .date-toggle {
    > .MuiAccordionSummary-content {
      display: flex;
      align-items: center;

      .MuiSvgIcon-root {
        margin-left: var(--linq-spacing-4);
      }
    }
  }

  .oo-accordion .MuiAccordionSummary-content {
    margin: 0px;
    text-transform: none;

    h4 {
      color: var(--linq-color-primary-600);
      margin: var(--linq-spacing-4) 0px;
    }
  }

  .oo-accordion .MuiAccordionSummary-root {
    background-color: #fff;
  }
  .oo-accordion .MuiAccordionSummary-content.Mui-expanded {
    margin: 0px;
  }

  .linq-button,
  .mdc-button,
  .mat-button-base,
  .MuiButtonBase-root:not(.MuiIconButton-root):not(.MuiChip-clickable) {
    padding-left: var(--linq-spacing-6);
    padding-right: var(--linq-spacing-6);
  }

  .MuiAccordionSummary-expandIcon {
    color: var(--linq-color-primary-600);
  }
}

.cart-sidebar__product-name,
.sidebar__total {
  color: var(--linq-color-gray-500);
  font-size: var(--linq-font-size-7);
  font-weight: bold;
  display: flex;
  align-items: center;
}

.cart-sidebar__sides {
  color: var(--linq-color-gray-500);
  margin-bottom: var(--linq-spacing-2);
  font-size: var(--linq-font-size-4);
}

.cart-sidebar__bulk-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--linq-spacing-2);
}

.cart-sidebar__bulk-product-category {
  color: var(--linq-color-primary-400);
  font-weight: bold;
  margin-bottom: var(--linq-spacing-4);
}

.cart-sidebar__number-input {
  width: 60px;
}

.entree-sidebar-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.entree-sidebar__product-image {
  max-height: 350px;
}

.entree-sidebar__divider {
  margin: 0px var(--linq-spacing-6) !important;
}

.side-bar__entree {
  color: var(--linq-color-gray-500);
  font-size: var(--linq-font-size-7);
  font-weight: 700;
}

.side-bar__product-name {
  color: var(--linq-color-primary-500);
  font-size: var(--linq-font-size-5);
  font-weight: bold;
  margin: var(--linq-spacing-4);
  margin-bottom: var(--linq-spacing-1);
  text-align: left;
}

.side-bar__bulk-product-name {
  text-transform: none !important;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.side-bar__sides {
  color: var(--linq-color-gray-500);
  font-size: var(--linq-font-size-4);
  margin: 0 var(--linq-spacing-4);
  text-align: left;
}

.bulk-order-table {
  height: 100%;
  width: 100%;
}

.bulk-order-card {
  display: flex;
  flex: 1;
}

.bulk-order-card__btn {
  width: 100%;
}

.bulk-order-card__image {
  cursor: pointer;
}

.bulk-order-card__product-body {
  flex: 0;
}

.bulk-order-card__btn.menu-item-container:last-child {
  margin-bottom: 0;
}

.bulk-order-card__btn.menu-item-container:focus,
.bulk-order-card__btn.menu-item-container-mobile:focus {
  transform: none;
  transition: none;
  box-shadow: none;
  cursor: default;
}

.bulk-order-card__btn.menu-item-container:hover,
.bulk-order-card__btn.menu-item-container-mobile:hover {
  cursor: unset;
}

.bulk-order-card__row {
  display: flex;

  .special-meal-accommodations-container .list .item .circle-icon {
    width: 28px;
    height: 28px;
    padding: 4px;
    font-size: 20px !important;
  }

  .special-meal-accommodations-container {
    padding: 0;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-right: var(--linq-spacing-4);

    .list .item {
      padding: 0;
    }
  }
}

.bulk-order-card__input {
  width: 60px;
}

.bulk-order-card__input-container {
  display: flex;
  flex-direction: column;
  margin-left: var(--linq-spacing-1);
}

.bulk-order-card__total-ordered {
  margin-left: var(--linq-spacing-3);
  font-size: var(--linq-font-size-4);
  color: var(--linq-color-secondary-600);
}

.bulk-order-card__total-ordered-none {
  color: var(--linq-color-gray-500);
}

.order-confirmation__entree {
  display: flex;
  justify-content: space-between;
}

.cart-sidebar__pickup-location-select {
  margin-right: var(--linq-spacing-6);
  margin-left: var(--linq-spacing-6);
  margin-top: var(--linq-spacing-6);
}

.date-toggle__icon {
  color: var(--linq-color-primary-600);
}
