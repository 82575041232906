.payment-provider-container {
  .linq-card.MuiPaper-elevation1 {
    margin-right: var(--linq-spacing-6);
  }

  h2 {
    margin: 0;
  }

  .oo-row {
    display: grid;
    grid-template-columns: 60% 40%;

    > div:first-child {
      grid-column: 2;
    }

    > div:last-child {
      grid-column: 1;
      grid-row: 1;
    }

    @media (max-width: 750px) {
      display: block;
    }
  }

  .payment-provider-card {
    flex: 1;
    margin: 0 20px 0 0;
  }
}
