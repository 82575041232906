@import 'variables';

.btn-base() {
  border: 2px solid transparent;
  font: 550 16px 'Proxima-Nova';
  letter-spacing: 0px;
  padding: 14px 16px;
  text-transform: uppercase;
  transition: all 200ms ease;

  &:focus {
    transition: all 200ms ease;
  }
}

.btn-inverted,
.button.btn-inverted {
  .btn-base();
  background-color: @white;
  border-color: @button-primary;
  border-radius: 4px;
  color: @button-primary;
  transition: all 200ms ease;

  &:focus {
    background-color: @white;
    border-color: @color-primary-dark;
    color: @color-primary-dark;
  }

  &.error {
    color: var(--linq-color-alert-200);
    border-color: var(--linq-color-alert-200);
    box-shadow: none !important;

    &:hover {
      background: var(--linq-color-alert-200);
      color: @white;
    }
  }
}
