@import 'Styles/variables';

.admin-sidebar-container {
  width: 500px !important;
  background-color: @white;
  max-width: 100%; // fit-content not supported by IE
  max-width: fit-content;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: @color-header;
    padding: 10px;
    color: @white;
  }

  .body {
    padding: 16px;
    height: 100%;

    p {
      font-weight: 600;
      color: @light-grey;
    }

    .MuiFormControl-root {
      margin-bottom: 0.5rem;
    }

    hr {
      margin: 15px 0;
      border: 1px solid @table-secondary;
    }

    .file-import-container {
      border: 1px dashed @table-secondary;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 150px;
    }
  }

  footer {
    position: sticky;
    bottom: 0;
  }
}
