@import 'Styles/variables';

.menu-item-modal-container {
  &.sidebar {
    overflow-y: hidden !important;
  }

  section {
    .product-category {
      text-transform: uppercase;
      text-align: left;
      font: 14px/24px 'Source Sans Pro';
      letter-spacing: 0px;
      color: var(--linq-gray-500);
      opacity: 1;
    }

    .name {
      text-align: left;
      font: bold 28px/40px 'Proxima-Nova';
      letter-spacing: 0px;
      color: @color-primary;
      opacity: 1;
    }

    .price {
      text-align: left;
      font: 18px/24px 'Proxima-Nova';
      letter-spacing: 0px;
      color: @dark-grey;
      opacity: 1;
    }

    .description {
      text-align: left;
      font: 16px/20px Source Sans Pro;
      letter-spacing: 0.29px;
      color: #343434;
      opacity: 1;
      margin: 8px 0px 8px 0px;
    }

    .ingredients {
      text-align: left;
      font: 16px/20px Source Sans Pro;
      letter-spacing: 0.29px;
      color: #343434;
      opacity: 1;
      margin-top: 4px;
    }

    .view-nutrition-info {
      color: @primary-blue;
      font: 16px/24px Proxima-Nova;
      letter-spacing: 0px;
      opacity: 1;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .food-groups {
      margin: 8px 0;

      .name {
        text-align: left;
        font: 14px/24px Source Sans Pro;
        letter-spacing: 0px;
        color: @dark-grey;
        text-transform: uppercase;
        opacity: 1;
      }
    }

    .pickup-location {
      margin-top: 24px;
      margin-bottom: 10px;

      .label {
        text-align: left;
        font: 14px/24px Source Sans Pro;
        letter-spacing: 0px;
        color: @dark-grey;
        text-transform: uppercase;
        opacity: 1;
      }

      .value {
        text-align: left;
        font: 16px/24px Source Sans Pro;
        letter-spacing: 0px;
        color: #343434;
        opacity: 1;
      }
    }
  }

  .order-details {
    font-family: 'Proxima-Nova';
    margin-top: 32px;

    .header {
      color: @primary-blue;
      font: 16px/24px Proxima-Nova;
    }

    .date {
      font-size: 14px;
      margin: 12px 0;
      color: @dark-grey;
    }

    .oo-row {
      display: flex;
      align-items: center;

      > div:not(:first-child):not(:last-child) {
        margin: 0 16px;
      }

      .menu-type.heading {
        text-transform: uppercase;
      }

      .menu-type {
        flex: 2;
      }

      .quantity {
        flex: 0 75px;
      }
      .total {
        flex: 1;
      }

      .delete {
        flex: 0 40px;
      }
    }
  }
}

.menu-item-accordion.MuiAccordion-root {
  box-shadow: none;

  &:before {
    display: none;
  }

  .MuiAccordionSummary-root {
    padding: 0;
    min-height: auto;
  }

  .MuiAccordionSummary-content {
    margin: 0;
    text-transform: none;

    h4 {
      color: var(--linq-color-primary-600);
      margin: var(--linq-spacing-4) 0px;
    }
  }

  .MuiButtonBase-root:not(.MuiIconButton-root):not(.MuiChip-clickable) {
    padding-left: 0px;
    padding-right: 0px;
  }

  .MuiAccordionSummary-expandIcon {
    color: var(--linq-color-primary-600);
    font-size: var(--linq-font-size-6);
    padding-top: 0;
    padding-bottom: 0;
  }
}

.menu-item-accordion__product-image {
  max-width: 100%;
  max-height: 60vh;
  margin: var(--linq-spacing-8) auto;
}
