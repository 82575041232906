@import 'Styles/variables';

.react-table-container {
  position: relative;

  .MuiFormControl-root .MuiInputLabel-outlined:focus {
    transform: none;
  }

  .MuiFormControl-root .MuiInputLabel-outlined {
    transform: translate(8px, 10px) scale(1);
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }

  table {
    width: 100%;
    border-width: 0;
    border-collapse: collapse;

    tr {
      width: 100% !important;
    }

    th:not(:nth-child(1)),
    td:not(:nth-child(1)) {
      flex: 1 !important;
      max-width: none !important;
    }

    thead {
      font-size: 14px;
      color: @text-primary;

      th {
        text-align: left;
        font: normal var(--linq-font-size-4) var(--linq-font-secondary);
        padding: var(--linq-spacing-4);

        &:nth-child(1) {
          position: sticky !important;
          background-color: @white;
          border-top-left-radius: 8px;
          z-index: 1;
          left: 0;
        }

        .header {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 0px 5px;
          width: 100%;
          font-size: var(--linq-font-size-4);

          &:focus {
            color: @color-primary;
          }

          .arrows {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transform: translate(0px, -6px);
            font-size: 20px;

            &[disabled],
            .disabled {
              opacity: 0.5;
            }

            .sort-up {
              margin-bottom: -16px !important;
            }
          }
        }
      }
    }

    tbody {
      td {
        padding: var(--linq-spacing-4);
        color: @text-secondary;
        font-size: 15px;
        border-top: 1px solid @background-color;

        &:nth-child(1) {
          position: sticky !important;
          background-color: @white;
          z-index: 1;
          left: 0;
        }

        div {
          display: flex;
          height: 100%;
          align-items: center;
        }
      }

      tr:last-child {
        td {
          border-bottom: 1px solid @background-color;
        }
      }
    }
  }

  .pagination {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    color: @text-secondary;

    .print {
      text-transform: uppercase;
      color: @primary-blue;
      padding: 0.25rem;

      &:focus {
        outline: 1px solid @color-primary;
      }
    }

    select {
      border: 0;
      margin-right: 50px;
      font-family: 'Source Sans Pro';
      font-size: 16px;
      color: @text-secondary;
      text-transform: uppercase;
      background-color: transparent !important;
    }

    .page-count {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
      padding-bottom: 2px;
      margin-right: 50px;
    }

    .arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      font-size: 12px;
      color: @text-secondary;
      cursor: pointer;

      &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &:focus {
        outline: 1px solid @color-primary;
      }
    }
    .first-page {
      svg:nth-child(2) {
        transform: translate(-15px, 0px);
      }
    }
    .last-page {
      svg:nth-child(1) {
        transform: translate(15px, 0px);
      }
    }
  }

  .no-data-row {
    height: 48px;
  }
}

// Classes for column and cell styles
.react-table-action-column {
  position: sticky;
  background-color: #ffffff;
  z-index: 1;
  right: 0;
}

.react-table-numeric-column {
  text-align: right;
}

.react-table-center-column {
  text-align: center;
}

.react-table-unit-label {
  text-align: right;
  margin: var(--linq-spacing-4);
  font-weight: bold;
}

.action-column-container {
  .kebab-menu {
    padding: 8px;
  }
}

.column-actions {
  .MuiListItemIcon-root {
    min-width: 32px;
  }
}

.react-table__no-data-msg {
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  text-align: center;
}

.react-table__no-data-msg-filterable {
  position: absolute;
  top: 140px;
  left: 0;
  right: 0;
  text-align: center;
}
