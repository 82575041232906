.filter-container {
  h6 {
    margin: 0px;
  }

  .checkbox-container {
    padding-left: 0px;
  }

  .dateRange-header {
    margin-bottom: var(--linq-spacing-2);
  }

  overflow-x: hidden !important;

  .filter-chip {
    margin-bottom: var(--linq-spacing-2);
    margin-right: var(--linq-spacing-1);
  }
  .selected-filters {
    margin-bottom: var(--linq-spacing-4);
  }
  .checkbox-container {
    margin-bottom: var(--linq-spacing-6);
  }
}
