.teacher-roster-ordering__entree-section {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  h4 {
    margin-bottom: 0;
    margin-top: 0;
  }

  > * {
    padding: var(--linq-spacing-6);
  }

  footer {
    padding: var(--linq-spacing-4);
  }

  .menu-item-container {
    width: 100%;
    margin: 0;
    margin-bottom: var(--linq-spacing-6);
    background-color: var(--linq-color-gray-100);
  }

  border: 1px solid var(--linq-color-secondary-500);
  border-radius: 4px;

  .special-meal-accommodations-container {
    margin: var(--linq-spacing-4);
    margin-bottom: 0;

    .list {
      flex-direction: row-reverse;

      .item {
        padding: 0;
      }
    }
  }

  .entrees-unavailable {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
    font-size: var(--linq-font-size-6);
    border-radius: 4px;
    padding: var(--linq-spacing-4) 0;
    background: #eff1f4;

    .MuiSvgIcon-root {
      color: var(--linq-color-warn-100);
      margin-right: var(--linq-spacing-2);
    }
  }
}

.teacher-roster-ordering__entree-list {
  background: var(--linq-color-gray-100);
  flex: 1;
  overflow-y: auto;
  flex-basis: 0;
  min-height: 472px;
  display: flex;
  flex-direction: column;

  .menu-item-container {
    min-height: unset;
  }

  .menu-item-container .body,
  .menu-item-container-mobile .body {
    height: auto;
  }
}
