.MuiDialog-container {
  .MuiPaper-root {
    border-radius: 4px;

    .MuiDialogActions-root {
      background: #f9fafb;
      padding: 1rem 1rem;
      border-top: 1px solid rgba(34, 36, 38, 0.15);
      text-align: right;
    }
  }
}
