@import 'Styles/variables';

.disclaimer-modal__form {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.disclaimer-modal__form.showClose {
  justify-content: flex-end;
}

.disclaimer-modal__checkbox-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .checkbox {
    margin-right: 10px;

    input + label:after {
      color: @color-primary;
    }

    input:focus + label {
      &:before {
        background: @lighter-grey;
        outline: 1px solid @color-primary;
        outline-offset: 3px;
      }
    }
  }
}
