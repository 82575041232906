@import 'Styles/variables';

.student-table-container,
.student-table-container-mobile {
  .student-table {
    margin: var(--linq-spacing-4);
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;

    h1 {
      margin-bottom: 0;
    }
  }

  #query {
    background: rgba(0, 0, 0, 0.09);
    height: 30px;
    padding: 10px;
    margin-top: -13px;
    input {
      width: 300px;
    }
  }
  .info {
    display: flex;
    justify-content: flex-end;

    font: 14px/24px Source Sans Pro;

    span {
      cursor: pointer;
    }

    .lastUpdated {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
    }
  }

  .btn-reset {
    color: @primary-blue;
  }
  .student-table-inactive-row {
    color: var(--linq-color-alert-100);
  }
}
