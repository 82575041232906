.parent-newsfeed-container {
  margin: var(--linq-spacing-6);
  margin-bottom: 73px;

  .fr-view {
    padding: var(--linq-spacing-6);
  }
}

.parent-newsfeed__close-btn {
  margin: 0 var(--linq-spacing-6) !important;
}
