@import 'Styles/variables';
@import 'Styles/slideout';

.MuiListSubheader-root {
  border-bottom: 1px solid #e3e2e2;
  height: 0;
}
.pickup-container {
  .day {
    color: var(--linq-color-primary-600);
    margin: var(--linq-spacing-6);

    .title {
      font-size: var(--linq-font-size-7);
      font-weight: bold;
      color: var(--linq-color-secondary-700);
    }

    .dayContents {
      justify-content: space-between;

      &.mobile {
        display: block;
      }

      .item {
        margin: var(--linq-spacing-4) 0;
        width: 100%;

        .MuiFormControl-root {
          margin-bottom: 0;
        }
      }
    }
  }
  hr {
    width: 100%;
  }
}
.pickup-container .day:last-child {
  hr {
    display: none;
  }
}
