@import 'Styles/variables';

.form-container {
  .food-group-wrapper {
    display: inline-block;
  }

  .circle-icon {
    margin-right: 10px;
    margin-bottom: 6px;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    text-align: center;
    line-height: 100px;
    vertical-align: middle;
    font-size: 26px !important;
    background: #c7ced8 0% 0% no-repeat padding-box;
    opacity: 1;
    color: #ffffff;
    letter-spacing: 0.32px;
    padding: 4px;
  }

  .body {
    padding: 20px;
    color: @light-grey;
  }
}
