header.sub-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--linq-spacing-6) var(--linq-spacing-6) var(--linq-spacing-8) var(--linq-spacing-6);

  .sub-header__title {
    padding: 0;
    margin: 0;
  }

  .sub-header__content {
    display: flex;
    flex: 1;
  }

  .sub-header__content-secondary {
    margin-left: var(--linq-spacing-4);
  }

  .sub-header__actions {
    button {
      margin-left: var(--linq-spacing-4);
    }
  }

  .sub-header__action {
    color: var(--linq-color-primary-500);
  }
}

header.sub-header--mobile {
  flex-direction: column;

  .sub-header__content {
    margin-bottom: var(--linq-spacing-6);
  }
}
