.froala-container {
  margin: var(--linq-spacing-4) 0;

  .fr-box.fr-basic.fr-top .fr-wrapper,
  .fr-toolbar.fr-desktop,
  .fr-second-toolbar {
    border-color: var(--linq-color-gray-400);
  }
}

.froala-container:hover,
.froala-container.froala-container__error {
  .fr-box.fr-basic.fr-top .fr-wrapper,
  .fr-toolbar.fr-desktop,
  .fr-second-toolbar {
    border-color: var(--linq-color-primary-500);
  }
}

.froala-container.froala-container__error {
  .fr-box.fr-basic.fr-top .fr-wrapper,
  .fr-toolbar.fr-desktop,
  .fr-second-toolbar {
    border-color: var(--linq-color-alert-200);
  }
}

.froala-editor__error {
  color: var(--linq-color-alert-200) !important;
}
