@import 'Styles/variables';

.meal-labels-container {
  *,
  :after,
  :before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }

  position: relative;
  width: 8.5in;
  margin: 0 auto;
  padding-left: 0.18in;
  background-color: @white;
  box-sizing: border-box;

  @media print {
    margin: 0;
    border: 0;
    padding: 0;
  }

  table {
    width: 100%;
    border-spacing: 0;
    box-sizing: inherit;
  }

  .meal-label-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 4in;
    height: 2in;
    margin-right: 0.128in;
    float: left;
    padding: 40px 25px 5px;
    border: 1px dashed #cccccc;
    position: relative;

    @media print {
      border: 0;
      margin-bottom: 0;
    }

    .student-name {
      font-weight: bold;
      text-transform: initial;
      font-size: 14px;
    }

    .student-id {
      font-size: 13px;
      line-height: 12px;
      margin-bottom: 6px;
    }

    .order-details {
      font-size: 12px;
      line-height: 13px;
    }
    .allergens {
      font-size: 12px;
      font-weight: bold;
      margin-top: 6px;
    }
    .date {
      font-size: 12px;
      position: absolute;
      top: 35px;
      right: 15px;
    }

    .pickup-location {
      font-size: 12px;
      font-weight: bold;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .comments {
      font-size: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-top: var(--linq-spacing-3);
    }

    .barcode {
      text-align: center;
      position: absolute;
      bottom: 0px;
    }

    &.contains-allergens {
      .student-id,
      .allergens,
      .order-details {
        margin-bottom: 0;
        line-height: 12px;
      }
    }
  }
  .meal-label-container.weekly {
    padding: 13px;
    .order-details {
      height: initial;
      font-size: 12px;
    }
    .barcode {
      font-size: 12px;
    }
    .student-id {
      display: inline;
    }
    .pickup-location {
      font-size: 12px;
    }
  }
}

.meal-labels__row {
  display: block;
  break-inside: avoid;
}
