@import 'Styles/variables';

.oo-drawer-main-section {
  padding: 1.5rem;
}

#a-la-carte-sidebar-slideout {
  .description-container {
    position: relative;
  }

  .textarea-length {
    position: absolute;
    top: var(--linq-spacing-1);
    right: var(--linq-spacing-2);
    color: #94a2b6;
  }
}

.input-group {
  border: none;
  margin: 1.5rem 0;
  padding: 0;

  &:first-child {
    margin-top: 0;
  }

  .caption {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }
}

.meal-availability-checkboxes {
  .mdc-text-field-helper-text--validation-msg {
    margin-left: var(--linq-spacing-7);
  }
}

.nutritional-information-wrapper {
  .fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;

    .MuiFormControl-root {
      margin-bottom: 0;
    }

    .input-full {
      grid-column: 1 e('/') -1;
    }
  }
}

.Mui-disabled,
.Mui-disabled label,
.Mui-disabled input {
  cursor: not-allowed;
}

.a-la-carte-editing {
  .add-button {
    width: auto;
  }
}

.MuiButtonBase-root.cancel-button {
  margin-right: var(--linq-spacing-2);
}

.a-la-carte__img-container {
  margin: var(--linq-spacing-8) auto;
  margin-top: 0;
  text-align: center;
  max-width: 100%;
  height: 300px;
}

.a-la-carte__product-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
