@import 'Styles/variables';

.district-reports-container {
  .report-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: @white;
    height: 82px;
    padding-left: 20px;
    box-shadow: 0 3px 6px #00000029;
  }
  .report-header h1 {
    margin-bottom: 0;
  }
  .report-day-week-toggle {
    margin-right: 20px;
  }
  @media (max-width: 700px) {
    .product-total-weekly-container {
      display: block;
      margin-top: 10px;
      .ordersCount {
        margin-right: 0;
      }
      .ordersCount:first-of-type {
        margin-bottom: 20px;
      }
    }
  }
  .student-orders-weekly-container {
    margin-top: 20px;
  }
  .report-body {
    padding: 20px;
  }

  .report-buttons {
    display: flex;
  }

  .meal-type-picker-container {
    margin: 0 !important;
  }

  .product-totals {
    margin-top: 32px;
    display: flex;
    flex-wrap: wrap;
  }

  .no-meals-message {
    font-size: x-large;
    font-weight: bold;

    .MuiPaper-root {
      margin-top: 2rem;
      text-align: center;
    }
  }

  &-mobile {
    .totals-container {
      display: block;

      .react-table-container,
      .totals-empty {
        margin-bottom: 1rem;
        margin-left: 0;
      }
    }
  }

  .date-range-nav-container {
    margin-left: auto;
  }

  .date-range-nav-container-mobile {
    margin-right: auto;
  }
}

.totals-container {
  display: flex;
  width: 100%;

  .totals-empty {
    align-items: center;
    background: @white;
    color: @text-primary;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
  }

  .react-table-container,
  .totals-empty {
    flex: 1;
    margin: 0;

    .react-table-scroll-wrapper {
      height: 100%;
    }

    tbody {
      max-height: 300px;
      overflow: auto;
      display: block;
    }
  }

  .react-table-container,
  .totals-empty {
    margin-left: 1rem;
  }

  .react-table-container:first-child {
    margin-left: 0;
  }
}
