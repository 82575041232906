@import 'Styles/variables';
body,
html {
  font-size: 16px;
  height: 100%;
}

// Buttons
.btn-reset {
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 0;
  font-family: 'proxima-nova', 'Source Sans Pro';
}

// Material UI extensions

.MuiListSubheader-root {
  border: none;
  height: auto;
  position: relative;
  top: auto;
}

.input-textarea-wrapper {
  color: @text-primary;
  background: var(--linq-color-gray-200);
  border-radius: 3px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 16px;
  margin-bottom: 1.5rem;
  padding: 1rem 0 0;

  label {
    color: @text-primary;
    display: block;
    padding: 0 1rem;
  }

  textarea {
    color: rgba(0, 0, 0, 0.87);
    background: transparent;
    border: none;
    display: block;
    margin: 0 !important;
    padding: 1rem;
    resize: none;
    width: 100%;

    &:focus {
      outline: none;
    }
  }
}

// IE11 support
main {
  display: block;
}

// Accessibility - screen reader only
.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// Page Layout
.application-root {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.main-content {
  flex: 1 0 auto;
}

.main-footer {
  flex-shrink: 0;
}

.Toastify__toast--info {
  background: @alert-info;
}
.Toastify__toast--success {
  background: @alert-success;
}
.Toastify__toast--warning {
  background: @alert-warning;
}
.Toastify__toast--error {
  background: @alert-error;
}

// Design System Overrides and Tweaks
.linq-button-close-lg.MuiButtonBase-root {
  padding: var(--linq-spacing-3);
  background-color: var(--component-background-color);
}

.linq-drawer-header__close.MuiButtonBase-root {
  margin-left: auto;
}

.linq-button-close-lg.MuiButtonBase-root:hover,
.linq-button-close-lg.MuiButtonBase-root:active {
  background-color: var(--component-active-background-color);
  box-shadow: var(--component-active-box-shadow);
}

.MuiButtonGroup-root {
  border-radius: 8px !important;
  background-color: var(--linq-color-gray-100);
}

.linq-app-bar .MuiButtonBase-root.MuiIconButton-root {
  margin-left: 0px;
  padding: 0px;
  width: 40px;
  height: 40px;
}

.page-content-wrapper {
  padding: var(--linq-spacing-12) var(--linq-spacing-8);
}

.page-layout {
  max-width: 800px;
  margin: auto;
  padding: var(--linq-spacing-6) var(--linq-spacing-4);
}

.MuiFormHelperText-root.linq-input__error-text {
  margin-left: var(--linq-spacing-4);
  font-size: 12px;
  font-family: 'proxima-nova', 'Source Sans Pro';
}

// @TODO move styles into DS
.linq-dialog--lg .MuiPaper-root {
  width: 35rem;
  max-width: 90vh;
}

.oo-accordion .MuiAccordion-root.Mui-expanded {
  margin-top: 0;
}
.oo-accordion.MuiAccordion-root.Mui-expanded {
  margin-top: 0;
}

.oo-accordion.MuiAccordion-root:before {
  height: 0;
}

.oo-accordion.MuiAccordion-root {
  box-shadow: none;
  border: 0;
  border-radius: 0;
  margin-top: 0;
}

.oo-accordion {
  .MuiButtonBase-root:not(.MuiIconButton-root):not(.MuiChip-clickable) {
    border-radius: 0;
  }

  .MuiAccordionSummary-content {
    margin-left: var(--linq-spacing-4);
    font-size: var(--linq-font-size-6);
    color: var(--linq-color-primary-600);
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin-left: var(--linq-spacing-4);
  }

  .MuiCollapse-container {
    padding: 0 var(--linq-spacing-4);
  }
}

.text-underline-none {
  text-decoration: none !important;
}

.linq-app-bar {
  .MuiBadge-badge.MuiBadge-anchorOriginTopRightRectangle {
    transform: none;
  }
}

.linq-file-upload-target__icon {
  margin-bottom: var(--linq-spacing-4);
}

.linq-card.MuiPaper-elevation1 {
  box-shadow: none;
}

.profile-card__no-image.profile-card__no-image {
  font-size: 100px;
}
.MuiInputBase-root,
.MuiFormControl-root,
.mdc-text-field,
.mat-form-field,
.mat-form-field-wrapper {
  --component-hover-color: var(--linq-color-primary-600) !important;
  --component-label-color: var(--linq-color-primary-500) !important;
}

.linq-drawer-footer {
  min-height: 36px;

  .linq-button,
  .mdc-button,
  .mat-button-base,
  .MuiButtonBase-root:not(.MuiIconButton-root):not(.MuiChip-clickable) {
    height: 100% !important;
  }
}

.MuiFormHelperText-root,
.MuiFormControl-root,
.MuiInputLabel-root,
.MuiInputBase-input {
  font-family: var(--linq-font-primary) !important;
}

.linq-card__h6 {
  color: var(--linq-color-gray-600);
  font-size: var(--linq-font-size-4);
}

.MuiFormHelperText-root.Mui-error {
  color: var(--linq-color-alert-200) !important;
}

.MuiListItem-button {
  border-radius: 0 !important;
  font-family: var(--linq-font-primary) !important;
  text-transform: initial !important;
  font-weight: normal !important;
}

a {
  text-decoration: none;
  color: inherit;
}

.spin {
  animation: spinning 2s infinite linear;
  display: inline-block;
}

@keyframes spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.view-picker-container {
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  text-align: center;
  width: 300px;
  padding: 5px 20px;
  margin-top: 7px;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 48px;
    text-transform: uppercase;
    font: 16px/24px 'Source Sans Pro';
    letter-spacing: 0px;
    color: #56667d;
    opacity: 1;
    border-bottom: 2px solid #cdd3d6;
    cursor: pointer;
    &.selected {
      color: #0f71b6;
      border-bottom: 2px solid #0f71b6;
    }
  }
}

.sub-header-family {
  padding: 0px !important;
  margin-bottom: 16px;
}

.page-layout-family {
  max-width: 800px;
  margin: auto;
  padding: var(--linq-spacing-6) var(--linq-spacing-4);
}
