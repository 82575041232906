@import 'Styles/variables';

.special-meal-accommodations-container {
  padding: 0 0 10px;

  .label {
    margin-right: 4px;
    text-align: left;
    font: 13px/24px Source Sans Pro;
    letter-spacing: 0px;
    color: @dark-grey;
    text-transform: uppercase;
    opacity: 1;
  }

  .list {
    display: flex;
    flex-wrap: wrap;

    .item {
      width: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 8px 0px 0px;

      .circle-icon {
        width: 30px;
        height: 30px;
        padding: 5px;
        border-radius: 50%;
        text-align: center;
        vertical-align: middle;
        font-size: 30px !important;
        background: #ffc400 0% 0% no-repeat padding-box;
        opacity: 1;
        color: #ffffff;
        letter-spacing: 0.32px;
      }

      .accommodation-text {
        text-align: left;
        font: 13px/16px Source Sans Pro;
        letter-spacing: 0px;
        color: #0f71b6;
        opacity: 1;
      }
    }
  }

  .custom {
    margin-top: 10px;
    display: flex;
    align-items: center;
    font-size: 12px;

    .label {
      margin-right: 4px;
      text-align: left;
      font: 13px/24px Source Sans Pro;
      letter-spacing: 0px;
      color: @dark-grey;
      text-transform: uppercase;
      opacity: 1;
    }

    .custom-acc-text {
      text-align: left;
      font: Bold 13px/24px Source Sans Pro;
      letter-spacing: 0px;
      color: @dark-grey;
      text-transform: uppercase;
    }
  }
}
