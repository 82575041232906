@import 'Styles/variables';

.pickup-location-report-table-container {
  margin-top: 18px;

  td {
    display: flex;
    align-items: center;
  }

  .react-table-container table tbody td div {
    flex-wrap: wrap;
  }

  .react-table-container .pickup-location-report__number-input div {
    flex-wrap: nowrap;
    height: auto;
  }
  .student-name-and-id {
    width: 100%;
    height: initial;
  }
  .barcode-weekly-table {
    width: 100%;
    max-width: 300px;
    height: initial;
  }

  .print {
    margin-right: 15px;
  }

  .item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    font: 20px Source Sans Pro;
    letter-spacing: 0px;
    color: @text-dark;
    max-width: 100%;
    height: auto;

    .student-name {
      font-weight: 550;
      padding-right: 5px;
    }

    &.link {
      color: @button-primary;
      font: 18px Source Sans Pro;
      cursor: pointer;
    }

    .weekly-order {
      margin: var(--linq-spacing-0) 0;
      flex: 1 100%;
    }
  }

  .order {
    display: block;
  }

  .comments {
    display: block;
    font-size: 15px;
  }

  .student-img {
    // Override div style from ReactTable.less
    height: 40px !important;

    margin-right: 0.75rem;
    color: @text-dark;
  }

  .barcode {
    display: flex;
    align-items: center;
  }
}

.pickup-location-report-delete-meals-btn {
  background-color: transparent !important;
  margin-right: 0 !important;
  color: @button-primary !important;
  font-weight: normal !important;
}

.pickup-location-report__received.item {
  width: 100%;
  justify-content: center;
}
