@import 'Styles/variables';

@default-top-spacing: 65px;
@mobile-top-spacing: 57px;

.header-menu-container {
  margin-top: @default-top-spacing !important;
}

.header-menu-container.linq-drawer-lg.MuiDrawer-root {
  .MuiBackdrop-root {
    top: @default-top-spacing;
  }

  .MuiPaper-root.MuiDrawer-paper {
    top: @default-top-spacing;
    width: auto;

    &:before {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
      content: '';
      display: block;
      height: 7px;
      pointer-events: none;
      position: absolute;
      width: 100%;
      z-index: 4;
    }
  }

  &.header-menu-container-mobile {
    .MuiBackdrop-root {
      top: @mobile-top-spacing;
    }

    .MuiPaper-root.MuiDrawer-paper {
      top: @mobile-top-spacing;
      width: 100%;

      .linq-global-menu {
        width: 100%;
      }
    }
  }
}
