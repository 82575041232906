@import 'Styles/variables';

.header-wrapper.mdc-top-app-bar {
  position: relative;
}

.header-container {
  display: flex;
  height: 64px;

  .cart-container {
    display: inline-flex;
    margin-right: 0.5em;
    .linq-app-bar,
    .MuiBadge-colorPrimary {
      span {
        border: none;
        box-shadow: none;
      }
    }
  }

  .header-actions {
    .items-in-cart {
      padding: 4px !important;
      margin-left: -1.4em;
      top: -0.3em;
      height: 24px;
      min-width: 24px;
      min-height: 1em;
      background-color: var(--linq-color-primary-200);
      border: 1px solid var(--linq-color-gray-100);
      color: var(--linq-color-primary-contrast-200);
      transition: transform 200ms ease-in-out;
      position: relative;
      cursor: default;
    }
  }
}

.user-actions {
  td {
    cursor: pointer;
    color: @href;
  }
}

.header-skip-to-content {
  background: var(--linq-color-primary-300);
  border: none;
  border-radius: 0;
  color: @white;
  height: 100%;
  padding: 0 1rem;
  position: absolute;
  left: 0;
  outline: none;
  transform: translateX(-150%);
  transition: all 300ms ease;
  z-index: 1000;

  &:focus {
    transform: translateX(0%);
    transition: all 300ms ease;
  }
}

.header__menu-item-name {
  .MuiTypography-body1 {
    font-weight: bold;
  }
}

.header__profile-name {
  padding: var(--linq-spacing-4) !important;
}

.header__menu-item-text {
  text-transform: capitalize !important;
  border-radius: 0 !important;
  font-weight: normal !important;
  padding: var(--linq-spacing-4) !important;
}

.header__menu-item-text:hover {
  background-color: var(--linq-color-gray-300) !important;
}

.header__menu {
  min-width: 200px;
  padding-top: 0 !important;
}

.header__menu-divider {
  margin-right: var(--linq-spacing-2) !important;
  margin-left: var(--linq-spacing-2) !important;
}

.header__logo {
  height: 16px;
}

.mdc-top-app-bar__title{
  padding-left: 0px !important;
  margin-left: var(--component-title-margin-left);
}
