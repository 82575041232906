@import 'Styles/variables';

.nutrition-facts-container {
  min-width: 250px;
  padding: 10px 0;
  font-size: 14px;
  color: @product-name-dark !important;

  display: table;

  td {
    padding: 2px 6px;
    display: table-cell;

    &.label {
      font-weight: bold;

      p {
        font-weight: normal;
        margin: 0;
      }

      &.secondary {
        font-weight: normal;
        padding-left: 15px !important;
      }

      .trans-fat-label {
        font-style: italic;
      }
    }

    &.value {
      text-align: right !important;

      &.bolded {
        font-weight: bold;
      }

      &.calories {
        font-size: 44px;
      }
    }
  }

  .no-border {
    border-top: 0 !important;
    font: 17px Source Sans Pro;
    letter-spacing: 0px;
    color: @dark-grey;
    opacity: 1;
  }

  .thick-border {
    border-top: 4px solid @product-name-dark !important;
  }

  .thick-border-bottom {
    border-bottom: 4px solid @product-name-dark !important;
  }

  .nutritional-link {
    font: 16px/24px Proxima-Nova;
    letter-spacing: 0px;
    color: @href;
    opacity: 1;
    cursor: pointer;
  }

  .additional-info {
    text-align: left;
    font: 13px/18px Source Sans Pro;
    letter-spacing: 0.23px;
    color: var(--linq-color-gray-500);
    opacity: 1;
    margin-top: 8px;
  }
}
