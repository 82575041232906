.calendar-block__row {
  border: 1px solid var(--linq-color-gray-500);
  border-collapse: collapse;
}

.calendar-block {
  border: 1px solid var(--linq-color-gray-100);
  border-collapse: collapse;
  width: 50px;
  font-size: var(--linq-font-size-5);
}

.calendar-block__item {
  padding: var(--linq-spacing-2);
  border-right: 1px solid var(--linq-color-gray-500);
  text-align: center;
}

.calendar-block__header-row {
  .calendar-block__item {
    border-right: 0;
    padding: 0;
    font-size: var(--linq-font-size-4);
  }
}

.calendar-block__item.blacked-out {
  color: var(--linq-color-gray-400);
}

.calendar-block__item.ordered {
  color: var(--linq-color-gray-100);
  background: var(--linq-color-gray-900);
  border: 1px solid var(--linq-color-gray-500);
}

.calendar-block__table-title {
  color: var(--linq-color-gray-500);
  text-transform: uppercase;
  font-size: var(--linq-font-size-4);
}
