@import 'Styles/variables';

.notification-dialog-container {
  .MuiPaper-root {
    width: 500px;
  }

  .MuiDialogContent-root {
    padding: 2rem;
  }

  .MuiDialogActions-root {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  header {
    padding: 1rem;
    background: @color-primary;
    color: @white;
    font-size: 110%;
    font-weight: bold;
  }

  .icon.circle {
    color: @color-primary;
    &.warning {
      color: @alert-warning;
    }
    &.delete {
      color: @alert-error;
    }
  }
}
