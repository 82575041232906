@import 'Styles/variables';

.pricing-management-container {
  .body {
    padding: 23px;
  }

  .picker-container {
    display: flex;
    margin-bottom: 1rem;
  }
}
