@import 'Styles/variables';

body {
  margin: 0;
  font-family: var(--linq-font-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: @background-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'proxima-nova', 'Source Sans Pro';
  color: @product-name-dark;
}

.oo-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &.edges {
    justify-content: space-between;
  }
}

.column {
  display: flex;
  flex-direction: column;

  &.stretch {
    flex: 1;
  }
}

.no-highlight {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.dialog-overflow {
  overflow-y: hidden;
}
