.account-configuration-container {
  .body {
    padding: 48px 40px 0;

    h2 {
      margin: 0;
      color: var(--linq-color-primary-500);
    }

    .account-configuration-card {
      max-width: 656px;
      flex: 1;
      margin: auto;

      .message,
      .header {
        color: #343434;
        font-size: 16px;
        margin-bottom: var(--linq-spacing-6);
        margin-top: 0;
      }

      .submit-container {
        display: flex;
        justify-content: flex-end;
        flex: 1 0 100%;
      }
    }
  }
}

.switch-homeroom-cart-content {
  padding: 1rem 1rem 2rem;
}

.MuiDialogActions-root.switch-homeroom-cart-actions {
  display: flex;
  justify-content: space-between;
}
