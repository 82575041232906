@import 'Styles/variables';
@import 'Styles/buttons';

.pickup-locations-container {
  .oo-row {
    display: grid;
    grid-template-columns: 60% 40%;

    @media (max-width: 750px) {
      grid-template-columns: 1fr;

      > div:first-child {
        grid-row: 2;
      }
    }
  }

  .linq-card.MuiPaper-elevation1 {
    margin-right: var(--linq-spacing-6);

    @media (max-width: 750px) {
      margin-right: 0;
    }
  }

  .page-content-wrapper {
    h2 {
      margin: 0;
      color: var(--linq-color-primary-500);
    }
    h4 {
      margin: 0px;
    }
    .fa-3x {
      font-size: 0px !important;
      width: var(--linq-spacing-6);
      height: var(--linq-spacing-6);
    }

    .MuiList-padding {
      padding-bottom: var(--linq-spacing-8) !important;
    }

    .linq-list {
      .MuiListItem-root {
        color: var(--linq-color-primary-500);
        min-height: 56px;
        font-weight: 600;
        justify-content: space-between;

        .pickup-locations__icons {
          display: flex;
        }

        .MuiButtonBase-root {
          padding: 0px;
          width: 40px;
          height: 40px;
        }
      }

      .MuiListItem-gutters {
        padding-left: 0px !important;
        padding-right: 0px !important;
      }

      .pickup-locations {
        .group-label {
          align-items: center;
          justify-content: space-between;
          margin: var(--linq-spacing-6);
          font-weight: bold;
        }
        .pickup-location {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .name {
            word-break: break-all;
          }

          .classRoomToggle {
            margin-right: -10px;
          }

          .info-icon {
            margin-left: 10px;
            color: @border-grey-dark;
          }

          svg {
            font-size: 18px;
            color: var(--linq-color-secondary-300);
            cursor: pointer;
          }
        }
      }
    }
  }
}
