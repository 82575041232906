.menu-order-table {
  thead {
    display: none;
  }

  .MuiIconButton-root {
    padding: 0px !important;
  }

  .drag-cell {
    text-align: end;
  }
}

.menu-order-sidebar {
  .linq-drawer-inner-content,
  .linq-sheet-inner-content {
    overflow: hidden !important;
  }
}
