.MuiLinearProgress-colorPrimary {
  background-color: #ddd !important;
}

.MuiLinearProgress-root {
  margin-bottom: var(--linq-spacing-6);
  margin-top: var(--linq-spacing-2);

  &:before,
  &:after {
    content: '';
    height: inherit;
    background: transparent;
    display: block;
    border-color: #fff;
    border-style: solid;
    border-width: 0 6px;
    position: absolute;
    width: calc(((100% - 20px) / 5));
    z-index: 10;
  }

  &:before {
    left: calc((100% - 20px) / 5);
  }

  &:after {
    right: calc((100% - 20px) / 5);
  }
}

.red {
  .MuiLinearProgress-barColorPrimary {
    background-color: darkred;
  }
}

.orange-red {
  .MuiLinearProgress-barColorPrimary {
    background-color: orangered;
  }
}

.orange {
  .MuiLinearProgress-barColorPrimary {
    background-color: orange;
  }
}

.yellow-green {
  .MuiLinearProgress-barColorPrimary {
    background-color: yellowgreen;
  }
}

.green {
  .MuiLinearProgress-barColorPrimary {
    background-color: green;
  }
}
