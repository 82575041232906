.page-loader-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.component-loader-container {
  position: absolute;
  width: 100%;
  min-height: 200px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loader__inline {
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader__loader-text {
  margin-top: var(--linq-spacing-2);
}
