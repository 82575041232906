@import 'Styles/variables';

.timepicker-container {
  display: flex;

  .MuiFilledInput-input {
    padding: 0;
  }

  .MuiSelect-filled.MuiSelect-filled {
    padding-left: 21px;
    padding-right: 36px;
  }

  .MuiFormControl-root {
    margin-right: 0.5rem;
    justify-content: center;
    min-width: 110px;
  }

  .MuiMenu-paper {
    transition-duration: 0s !important;
  }

  &-mobile {
    .MuiFormControl-root {
      margin-right: 2px;
      flex: 1;
    }
    .MuiSelect-root {
      padding: 1rem;
    }
  }
}
