@import 'Styles/variables';

.teacher-roster-ordering__content {
  flex: 1;
}

.teacher-roster-ordering__ordering-table {
  min-height: 472px;
  height: 100%;
  width: 100%;

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .ordering-status {
      margin-right: var(--linq-spacing-3);
    }
  }
}

.teacher-roster-ordering__entree-section {
  height: 100%;
}

.teacher-roster-ordering-container {
  padding-left: var(--linq-spacing-4);
  padding-right: var(--linq-spacing-4);
  height: 100%;
  display: flex;
  flex-direction: column;

  header.sub-header {
    padding-right: 0;
    padding-left: 0;
  }

  .report-header {
    display: flex;
    align-items: center;
    background-color: @white;
    height: 82px;
    padding-left: 20px;
    box-shadow: 0 3px 6px #00000029;
  }

  .meal-type-picker-container {
    margin: 0 !important;
  }

  .print {
    padding: 0.25rem;
    text-decoration: none;

    &:focus {
      outline: 1px solid @color-primary;
    }
  }

  .order-items {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font: 20px 'Source Sans Pro';
    color: @text-dark;
    align-items: flex-start;

    .side-items {
      font-size: 14px;
    }
  }

  .item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    font: 20px 'Source Sans Pro';
    letter-spacing: 0px;
    color: #343434;

    .student-name {
      font-weight: 550;
      padding-right: 5px;
    }

    .student-img {
      // Override div style from ReactTable.less
      height: 40px !important;

      margin-right: 0.75rem;
      color: @text-dark;
    }
  }

  .ordering-rules {
    color: var(--linq-color-secondary-500);
    font-size: var(--linq-font-size-7);
    flex: 1 1;
    text-align: right;
    padding-left: var(--linq-spacing-6);
  }

  .table-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--linq-spacing-4) var(--linq-spacing-6);
    background-color: var(--linq-color-gray-300);

    a:active,
    a:focus {
      outline: none;
    }

    .footer-text {
      text-transform: uppercase;
      color: var(--linq-color-primary-700);
    }
  }
}

.row__actions {
  display: flex;
}

.row__bulk-switch {
  display: flex;
  height: 56px;
  align-items: center;
  font-weight: bold;
  margin-right: var(--linq-spacing-6);
}

@media (max-width: 540px) {
  .teacher-roster-ordering__bulk-switch.col-12 {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 1000px) {
  .teacher-roster-ordering__bulk-switch.col-xl-6,
  .teacher-roster-ordering__bulk-switch.col-lg4 {
    display: flex;
    justify-content: flex-end;
  }
}

.teacher-roster-ordering__row {
  height: 100%;
}

@media (max-width: 992px) {
  .teacher-roster-ordering__table-col {
    margin-bottom: var(--linq-spacing-4);
  }
}

@media (min-width: 992px) {
  .teacher-roster-ordering__table-col {
    padding-right: var(--linq-spacing-4) !important;
  }
}
