@import 'Styles/variables';

.calendar-day-container {
  pointer-events: auto;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: 100px;

  &.mobile {
    flex-direction: row;
    min-height: 157px;
  }
}

td.content {
  position: relative;
  vertical-align: top;

  &:first-child {
    padding-left: 10px !important;
  }
  &:last-child {
    padding-right: 10px !important;
  }
}

div.menu-items-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);

  &.clickable {
    pointer-events: none;
  }

  &.unavailable {
    width: 100%;
  }

  &.top-row {
    height: 100%;
  }
}

.ordering-unavailable {
  background-color: white;
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 50px;

  border-radius: 4px;
  border: 1px solid @border-grey;

  display: flex;
  justify-content: center;
  align-items: center;
}
.ordering-unavailable__warning-icon {
  margin-right: 10px;
  color: var(--linq-color-warn-100);
}
