.linked-parents-modal {
  .linq-dialog__content {
    padding-bottom: var(--linq-spacing-6) !important;
  }
  
  .table-no-data {
    min-height: 300px !important;
  }
  
  .MuiDialog-paper {
    // table width
    width: 1150px;
  }
  
  .Modal.Actions {
    justify-content: end !important;
  }
  
}

