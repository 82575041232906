button.linq-banner__action-clear {
  width: 28px;
  height: 28px;
  margin: auto;
}

.linq-banner__message {
  padding: var(--linq-spacing-2) !important;
}

.linq-banner__action {
  color: inherit !important;
}
