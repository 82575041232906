@import 'Styles/variables';

.invite-form {
  display: grid;
  grid-gap: var(--linq-spacing-4);
  width: 100%;
}

.invite-modal-container {
  .linq-dialog__content_scroll div {
    .MuiTextField-root {
      margin-bottom: 6px;
    }
  }
}

.invite-error {
  color: var(--linq-color-alert-200);
  padding-left: 16px;
}
