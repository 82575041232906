.order-confirmation-sidebar {
  .footer {
    justify-content: flex-end;
  }

  .pickup,
  .order-id {
    color: var(--linq-color-primary-400);
    font-size: var(--linq-font-size-8);
    font-weight: bold;
    margin: var(--linq-spacing-8);
    text-transform: uppercase;
  }

  .order-id {
    font-size: var(--linq-font-size-10);
  }

  .entree {
    display: flex;
    justify-content: space-between;
  }

  .student-row {
    margin-top: var(--linq-spacing-4);
    color: var(--linq-color-secondary-300);
  }

  .failed-message {
    margin-left: 54px;
    color: var(--linq-color-secondary-300);
  }
}
