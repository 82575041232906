.sites-container {
  padding-left: var(--linq-spacing-4);
}

.site-menu {
  padding-left: var(--linq-spacing-4) !important;
}

.site-menus-container {
  padding-bottom: var(--linq-spacing-1);
}

.no-menus-published {
  // text-align: center;
  padding-left: var(--linq-spacing-6);
  padding-top: var(--linq-spacing-3);
}

.school-menus-container {
  margin-top: var(--linq-spacing-5);

  .component-loader-container {
    align-items: unset;
    margin-left: 5rem;
  }

  h3 {
    margin-top: 0px;
    margin-bottom: var(--linq-spacing-1);
  }
}

.site-group-container {
  padding-top: var(--linq-spacing-3);
}
