@import 'Styles/variables';

.input-upload {
  display: flex;
  font-size: 16px;
  margin-bottom: 1.5rem;

  .input-value {
    align-items: center;
    background: var(--linq-color-gray-200);
    color: @text-primary;
    display: flex;
    flex: 1;
  }

  .input-placeholder {
    padding: 0 1rem;
  }

  .input-display {
    display: flex;
    padding: 1rem;
    width: 100%;

    a {
      color: @dark-grey;
      flex: 1;
      text-decoration: underline;
    }

    button {
      color: @dark-grey;
      cursor: pointer;
      font-size: 1.25rem;
    }
  }

  .linq-file-upload {
    flex: 1;
  }
}

.input-upload__close-btn {
  width: 24px;
  height: 24px;
  padding: 0;
}
