@import 'Styles/variables';

.linq-input.MuiTextField-root {
  margin-bottom: var(--linq-spacing-6);
}

.linq-input.linq-input__table-filter {
  margin-bottom: unset;

  .MuiOutlinedInput-input {
    padding: var(--linq-spacing-2) var(--linq-spacing-2);
  }
}

.MuiAutocomplete-root {
  .MuiFormControl-root {
    background: var(--linq-color-gray-100);
    border-radius: var(--linq-border-radius-2);

    input.MuiInputBase-input {
      border: 0;
    }
  }
}

.MuiTextField-root.currency,
.input.currency {
  input {
    text-align: right;
    padding-right: 1.5rem;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.message-error {
  color: var(--linq-color-alert-200);
  font-size: 12px;
  letter-spacing: 0.4px;
  margin-left: var(--linq-spacing-4);
  line-height: normal;
}

// Avoid jumpy errors
.MuiFormControl-root.MuiTextField-root {
  margin-bottom: var(--linq-spacing-8);

  .MuiFormHelperText-root {
    bottom: calc(var(--linq-spacing-6) * -1);
    position: absolute;
  }
}

// Override for jumpy error fix, accounts for multi line errors
.multi-line-error {
  margin-bottom: var(--linq-spacing-8) !important;

  .MuiFormHelperText-root {
    bottom: inherit !important;
    position: relative !important;
  }
}

.autocomplete-input {
  input {
    padding-top: var(--linq-spacing-3) !important;
    padding-bottom: var(--linq-spacing-3) !important;
  }
  .MuiAutocomplete-inputRoot {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.MuiMenu-paper {
  margin-top: 15px;
}

.select-input {
  .MuiSelect-select {
    padding-top: var(--linq-spacing-3) !important;
    padding-bottom: var(--linq-spacing-3) !important;
  }
  input {
    padding-top: var(--linq-spacing-3) !important;
    padding-bottom: var(--linq-spacing-3) !important;
    box-sizing: border-box;
  }
}

.keyboard-date-picker__error-text {
  color: var(--linq-color-alert-200) !important;
}

.autocomplete__list-box {
  padding: 0 !important;
  font-family: var(--linq-font-primary) !important;
}

.autocomplete__no-options-text {
  font-family: var(--linq-font-primary) !important;
}

.select-field__menu {
  .MuiMenu-list {
    padding: 0 !important;
  }
}

.select-field__menu-option {
  border-radius: 0 !important;
  font-family: var(--linq-font-primary) !important;
  text-transform: initial !important;
  font-weight: normal !important;
}

.date-range-picker {
  display: flex;
}

.date-range-picker__from-date {
  min-width: 48% !important;
  margin-right: 4% !important;
}
.date-range-picker__to-date {
  min-width: 48% !important;
}
.clear-input {
  margin-right: var(--linq-spacing-1) !important;
  min-width: 0px !important;
}

.switch-field {
  width: 100%;
}

.switch-error {
  color: var(--linq-color-alert-200);
}
.switch-error-msg {
  margin: var(--linq-spacing-4) !important;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-weight: 400;
}
.switch-disabled {
  color: var(--linq-color-gray-300);

  cursor: default !important;
}

.MuiAutocomplete-tag {
  background-color: var(--component-background-color) !important;
  border: 1px solid var(--linq-color-primary-500) !important;
  color: var(--linq-color-primary-500) !important;
  font-size: var(--component-font-size) !important;
  min-height: 26px !important;
  text-transform: uppercase !important;
  white-space: nowrap !important;
}
