.synthesis-container {
  .header {
    display: flex;
    align-items: center;
    height: 82px;
    padding-left: 24px;
    background-color: #ffffff;
    box-shadow: 0 3px 6px #00000029;
  }

  .page-content-wrapper {
    .oo-row {
      display: grid;
      grid-template-columns: 60% 40%;

      > div:first-child {
        grid-column: 2;
      }

      > div:last-child {
        grid-column: 1;
        grid-row: 1;
      }

      @media (max-width: 750px) {
        display: block;
      }
    }

    .card-body {
      margin-top: var(--linq-spacing-8);
    }

    .linq-card.MuiPaper-elevation1 {
      margin-right: var(--linq-spacing-6);

      @media (max-width: 750px) {
        margin-right: 0;
      }

      .MuiFormControl-root {
        width: 100%;
      }

      .card-footer {
        background-color: var(--linq-color-gray-300);
      }
    }
  }
}
