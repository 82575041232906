.linq-table-container {
  background: var(--linq-color-gray-100);
}

.filter-chip {
  margin-bottom: var(--linq-spacing-2);
  margin-right: var(--linq-spacing-1);
}

.linq-table__active-filters {
  align-items: center;
  display: flex;
  overflow: auto;
  margin-right: var(--linq-spacing-1);
  margin-top: var(--linq-spacing-2);

  @media only screen and (max-width: 786px) {
    padding: var(--component-filters-padding);
  }
}

@media only screen and (max-width: 630px) {
  .table-actions {
    margin-top: 52px;
  }
}

.linq-table__search-input {
  margin-bottom: 0 !important;
  min-width: 255px !important;
}

.search-button {
  margin-left: var(--linq-spacing-3) !important;
}

.table-cell__text-content {
  padding: var(--component-cell-spacing-y) var(--component-cell-spacing-x);
}

.pagination__outer-container {
  display: flex;
}

.paginator__container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 var(--linq-spacing-2);
  flex-wrap: wrap-reverse;
  width: 100%;
  font-family: var(--linq-font-primary);
  font-size: var(--linq-font-size-5);
}

.paginator__page-size {
  display: flex;
  align-items: baseline;
  margin-right: var(--linq-spacing-2);
}

.paginator__page-size-label {
  margin: 0 var(--linq-spacing-1);
  color: var(--component-paginator-color);
}

.paginator__range-actions {
  display: flex;
  align-items: center;
}

.paginator__range-label {
  margin: 0 var(--linq-spacing-8) 0 var(--linq-spacing-6);
}

.paginator__page-jump {
  color: var(--component-paginator-color);
  align-items: center;
  display: flex;
}

.paginator__page-input {
  width: 60px;
}

.linq-table__sort-icon {
  height: 12px !important;
  width: 12px !important;
  margin-left: var(--linq-spacing-1);
}

.react-table-v2-unit-label {
  font-weight: bold;
  padding: var(--component-filters-padding);
  margin-left: auto !important;
}

.table-filter-chips__clear {
  margin-left: auto !important;
}

.table-filter-chips {
  height: var(--linq-spacing-8) !important;
}

.react-table-v2-sticky-action-column {
  th:last-child {
    position: sticky !important;
    border-left: 1px solid var(--component-cell-border-color);
    background-color: white;
    z-index: 1;
    left: auto;
    right: 0;
  }

  th:nth-last-child(2) {
    border-right: none !important;
  }

  tbody {
    td:last-child {
      position: sticky !important;
      border-left: 1px solid var(--component-cell-border-color);
      z-index: 1;
      left: auto;
      right: 0;
    }
    td:nth-last-child(2) {
      border-right: none !important;
    }
  }
}
.table-overflow {
  min-height: 300px;
}
.table-loading {
  opacity: 0.5 !important;
}

.MuiInputBase-root.Mui-disabled .MuiOutlinedInput-notchedOutline,
.mdc-text-field--disabled {
  background-color: transparent !important;
  opacity: 0.5 !important;
}

.MuiInputBase-root.Mui-disabled {
  opacity: 0.5 !important;
}
