@import 'Styles/variables';

.datesList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected {
  background-color: @color-header !important;
}

.blackout-date-rules__date {
  margin-right: var(--linq-spacing-4);
  margin-bottom: var(--linq-spacing-4);
}
