.preview-menus-container {
  .view-picker-container {
    padding: 0px 0px var(--linq-spacing-8) 0px !important;
  }

  .date-range-nav-container {
    float: none !important;
  }
  padding: 0px var(--linq-spacing-8);

  &.mobile {
    padding: 0px var(--linq-spacing-2);
  }

  .linq-card__space {
    display: block !important;
    min-height: 500px;
  }

  a {
    text-align: left !important;
    text-transform: none !important;
    text-decoration: underline !important;
    color: var(--linq-color-primary-400) !important;
    font-weight: 100 !important;
  }
}
