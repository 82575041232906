@import 'Styles/variables';

.user-management-container {
  .body {
    margin: var(--linq-spacing-10) var(--linq-spacing-4) 0;

    h2 {
      margin: 0;
      color: #0b5487;
    }

    p {
      font-size: 16px;
      margin-top: 8px;
    }

    .react-table-container table thead tr,
    .react-table-container table tbody tr {
      position: relative;
    }

    .react-table-container table thead tr th:last-child {
      position: absolute;
      right: 0;
      border: none;
    }

    .react-table-container table tbody tr td:last-child {
      position: absolute;
      right: 0;
      border: none;
    }
  }

  .invite-btn {
    cursor: pointer;
    color: #4183c4;
  }
}

.table-filters {
  padding: 0 20px;
  .top-right {
    position: absolute;
    right: 22px;
    top: 104px;
  }
  .left {
    float: left;
    button {
      margin-top: 10px;
      cursor: pointer;
      color: var(--linq-color-primary-500);
    }
  }
  .right {
    margin-top: 10px;
    float: right;
  }
}

.user-management-modal-container {
  .header {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    background-color: #0b5487 !important;
    padding: 24px !important;
  }

  .content {
    padding: var(--linq-spacing-6);
  }

  .content div {
    width: 100%;
  }

  form {
    width: 100%;
  }
}
.select-all-invites {
  cursor: pointer;
}

.right-header {
  margin-right: 25px;
}

@media only screen and (max-width: 630px) {
  .table-filters {
    margin-top: 52px;
  }
}

.loading-indicator {
  width: 20px !important;
  height: 20px !important;
  margin-left: var(--linq-spacing-2) !important;
}

.loading-indicator-complete {
  animation: slowFade 2s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}

@keyframes slowFade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.user-management__modal-title.linq-heading {
  color: @white;
}
