@import 'Styles/variables';

.reimbursable-meal-pricing-item-container {
  margin-bottom: 20px;

  .name {
    font-size: 24px;
    font-weight: bold;
    color: @color-primary;

    .info {
      font: 16px 'Source Sans Pro';
      color: @text-dark;
      margin-top: 10px;
    }
  }

  .break-column {
    flex-basis: 100%;
    width: 0;
  }

  .pricing-group {
    flex: 1;
    margin-bottom: 2rem;
    margin-right: 0.5rem;
  }

  .site-group-name {
    margin: 5px 0 10px;
    text-align: left;
    color: @text-primary;
    text-transform: uppercase;
    white-space: nowrap;
    font: 14px 'Source Sans Pro';
  }

  .price {
    text-align: left;
    color: @text-dark;
    font: 16px 'Source Sans Pro';
  }

  .controls {
    display: flex !important;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    flex-wrap: wrap;
    height: 100%;

    svg {
      color: @icon-light-grey;
      cursor: pointer;
      align-self: center;
      font-size: 24px;
    }
  }

  .groups {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .group {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &-mobile {
    .break-column {
      display: none;
    }

    .pricing-group {
      flex: none;
      width: 50%;
      min-width: 15rem;
      margin-right: 0;
    }
  }
}

.add-grade-group {
  padding-left: 0px !important;
  margin-top: var(--linq-spacing-4) !important;
  margin-bottom: var(--linq-spacing-6) !important;
}
.grade-pricing-table {
  .action-buttons {
    display: flex;
  }

  .action-column-container .kebab-menu {
    padding: 0px !important;
  }
  .row-actions {
    display: flex;
    padding-left: var(--linq-spacing-4);
    padding-top: 0;
  }
  .actions-cell {
    padding: 0px;
  }
}

.reimbursable-meal-pricing__paper {
  padding: var(--linq-spacing-7);
}

.add-grade-group-modal {
  .MuiDialogContent-root {
    overflow: hidden;
  }

  .MuiDialog-paperWidthMd {
    width: 425px;
  }
}

.price-cell::before {
  content: '$';
}
