@import 'Styles/variables';

.student-card-container {
  margin: var(--linq-spacing-3);
  width: 462px;
}

.student-card-container-mobile {
  width: 100%;
}

.student-card-header__content {
  flex: 1;
}

.student-card-container,
.student-card-container-mobile {
  display: flex;
  flex-direction: column;

  .barcode {
    padding: 0 15px;
    display: none;

    &.mobile {
      display: block;

      svg {
        max-height: 300px;
        margin: 0 auto; /* poor attempt at centering */
        height: auto !important; /* overrides inline */
        width: auto !important; /* overrides inline */
        display: block;
        max-width: 100%;
      }
    }
  }

  .suspended-container {
    flex: 1 1 100%;
    color: @white;
    display: flex;
    align-items: center;

    .icon {
      margin-right: var(--linq-spacing-2);
      margin-top: var(--linq-spacing-2);
    }

    .message {
      text-align: left;
      font: bold 16px 'Source Sans Pro';
      font-weight: 550;
      letter-spacing: 0px;
      opacity: 1;
      min-height: 40px;
      padding-top: var(--linq-spacing-4);
    }
  }

  .student-data-label {
    text-align: left;
    font: 14px/20px 'Source Sans Pro';
    letter-spacing: 0.13px;
    color: @text-primary;
  }

  .student-data {
    padding-bottom: var(--linq-spacing-4);
    min-height: 44px;
    display: flex;
  }
  .student-data:last-child {
    padding-bottom: 0;
  }

  .student-data.mobile {
    padding-bottom: var(--linq-spacing-3);
  }

  .student-type-chip {
    margin-left: auto !important;
  }

  .days-ordered {
    display: inline-block;
    border-radius: 16px;
    width: 40px;
    height: 16px;
    background: var(--linq-color-secondary-100) 0% 0% no-repeat padding-box;
    opacity: 1;
    text-align: center;
    font: 13px/16px Source Sans Pro;
    letter-spacing: 0px;
    color: #083d64;
    text-transform: uppercase;
    margin-right: 5px;
  }

  .extra {
    border-top: 1px solid #eff1f4;
    text-align: right;
    font: 14px/24px Source Sans Pro;
    letter-spacing: 0px;
    color: @primary-blue;
    opacity: 1;
    position: absolute;
    bottom: 0px;
    width: 90%;
    padding-right: 16px !important;
  }

  .student-action {
    display: inline-block;
    color: var(--linq-color-primary-500) !important;
    padding-left: var(--linq-spacing-2) !important;
    padding-right: var(--linq-spacing-2) !important;
  }
  .student-action-mobile {
    color: var(--linq-color-primary-500) !important;
    padding-right: var(--linq-spacing-2) !important;
    padding-left: 0px !important;
  }
}

.student-card__card-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.student-card__actions {
  display: flex;
  justify-content: flex-end;
}

.student-card__card-content.MuiCardContent-root {
  padding: 0;
}

.student-card__card-content.MuiCardContent-root:last-child {
  padding-bottom: 0;
}

.student-card-header__picture.MuiAvatar-root {
  width: 100px;
  height: 100px;
}

.student-card__space {
  flex: 1;
}

.profile-card__no-image.student-card__no-image {
  color: @white;
}

.student-card__calendar-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.student-card__negative-balance {
  color: var(--linq-color-alert-100);
}

.student-card__alacarte-table-mobile {
  margin-top: var(--linq-spacing-4);
}
