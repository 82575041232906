.oo-error-page {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  height: 100%;

  svg {
    height: 1.5em;
    width: 1.5em;
    margin-right: 0.75rem;
  }
}

.oo-error-page__card {
  margin-top: 4rem;
  width: 40vw;
  min-width: 20rem;
  padding: 2rem;
}

.oo-error-page__header {
  margin: 0;
  display: flex;
}
