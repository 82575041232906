@import 'Styles/variables';

.scrolling-table-container {
  border: 1px solid var(--linq-color-secondary-500);
  border-radius: 4px;
  overflow: hidden;

  *,
  :after,
  :before {
    box-sizing: inherit;
  }

  .no-data-row {
    height: 48px;
  }

  .no-data-msg {
    z-index: 1;
    text-align: center;
    margin: auto;
  }

  .header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px var(--linq-spacing-2);
    width: 100%;

    &:focus {
      color: @color-primary;
    }

    .arrows {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transform: translate(0px, -4px);
      font-size: 20px;

      &[disabled],
      .disabled {
        opacity: 0.5;
      }

      .sort-up {
        margin-bottom: -16px !important;
      }
    }
  }

  .tbody {
    flex: 1;
    background: @white;
    min-height: 0;
    display: flex;
    flex-direction: column;
    flex-basis: 0;
    overflow-y: auto;
    max-height: 100%;
    box-sizing: border-box;

    .tr {
      display: flex;
      align-items: center;
      padding: var(--linq-spacing-4);
      width: 100%;
    }
  }
}

.scrolling-table__table {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.scrolling-table__thead {
  position: sticky;
  top: 0;
  background-color: @background-color;
  color: var(--linq-color-primary-700);
  z-index: 2;
  width: 100%;

  .tr {
    align-items: center;
    padding: var(--linq-spacing-2);
  }
}

.scrolling-table__header-btn {
  font-size: var(--linq-font-size-5);
}
