.terms-of-use {
  padding: 0px 0px 2px 2px !important;
  text-transform: none !important;
  font-family: var(--linq-font-primary) !important;
  font-weight: 400;
}

.agree-to-terms {
  align-items: end !important;
}

.agree-label {
  margin-left: var(--linq-spacing-1);
}

.change-email__container {
  margin-bottom: var(--linq-spacing-6);
}

.change-email {
  display: block;
}
.change-email__helper-text {
  display: block;
  margin: var(--linq-spacing-2) 0;
  font-size: 14px;
}
.update-email-btn {
  padding: 0 !important;
  margin-right: 1rem;
  font-family: var(--linq-font-primary) !important;
  font-size: var(--linq-font-size-5);
  font-weight: bold;
  color: var(--linq-color-primary-500);
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.update-password-btn {
  padding: 0 !important;
  font-family: var(--linq-font-primary) !important;
  font-size: var(--linq-font-size-5);
  font-weight: bold;
  color: var(--linq-color-primary-500);
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.disabled-btn {
  opacity: 0.5 !important;
  cursor: default !important;
}
