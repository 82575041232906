@import 'Styles/variables';

.teacher-ordering-report-container {
  width: 8.5in;
  margin: auto;
  background-color: @white;
  padding: 0 var(--linq-spacing-5);
  page-break-inside: avoid;

  .page-header {
    display: flex;
    justify-content: space-between;
  }

  .classroom-name {
    font-size: var(--linq-font-size-7);
    padding: var(--linq-spacing-5) 0 var(--linq-spacing-5) 0;
    text-align: left;
    font-weight: bold;
    text-transform: capitalize;
  }

  .date {
    font-size: var(--linq-font-size-5);
    padding: var(--linq-spacing-5) var(--linq-spacing-5) var(--linq-spacing-5) 0;
    text-align: right;
    font-weight: normal;
  }

  table {
    width: 100%;
    border-spacing: 0;

    thead {
      tr {
        page-break-inside: avoid;
        page-break-after: auto;

        th {
          padding: var(--linq-spacing-1);

          &.student-allergens {
            padding: var(--linq-spacing-5) var(--linq-spacing-5) var(--linq-spacing-5) 0;
          }

          &.product-date {
            padding: var(--linq-spacing-5) var(--linq-spacing-5) var(--linq-spacing-5) 0;
          }

          &.student-name {
            width: 125px;
            text-align: left;
            font-size: var(--linq-font-size-5);
            padding-left: var(--linq-spacing-2);
            font-weight: normal;
          }
        }
      }
    }

    td {
      padding: var(--linq-spacing-1);
      text-align: left;
      vertical-align: top;

      &.student-name {
        font-size: var(--linq-font-size-4);

        .student-id {
          font-size: var(--linq-font-size-3);
        }
      }
      &.product-name {
        font-size: var(--linq-font-size-4);
        max-width: 50px;
      }
      &.student-allergens {
        font-size: var(--linq-font-size-4);
        text-align: left;
        max-width: 5px;
      }
    }
  }
}

.teacher-report {
  border-collapse: collapse;
  margin-top: var(--linq-spacing-6);
}

.teacher-report__first-table {
  margin: 0;
}

.teacher-report__header-row {
  border-bottom: 1px solid #999999;
}

.teacher-report__tr {
  font-size: var(--linq-font-size-5);
  text-align: left;
  font-weight: normal;
  border-bottom: 1px solid #999999;
}

.teacher-report__pre-line {
  white-space: pre-line;
}

.teacher-report__sides {
  font-style: italic;
}

.teacher-report__page-break {
  page-break-after: always;
}
