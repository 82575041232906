@import 'Styles/variables';

.date-range-nav-container-mobile {
  display: flex;
  justify-content: center;
  margin: 0px 10px 30px;
}
.date-range-nav-container {
  float: right;
}

.date-range-nav-container,
.date-range-nav-container-mobile {
  display: flex;
  height: 56px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  .text {
    background: @white 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 2px #00000014;
    border-top: 1px solid #cdd3d6;
    border-bottom: 1px solid #cdd3d6;
    opacity: 1;
    display: inline-block;
    text-align: center;
    font: Bold 24px/32px Proxima-Nova;
    letter-spacing: 0px;
    color: var(--linq-color-primary-500);
    min-width: 240px;
    padding: var(--linq-spacing-3) var(--linq-spacing-4);
  }

  .date-picker {
    .MuiInputBase-input {
      text-align: center;
      box-shadow: 0px 1px 2px #00000014;
      border-top: 1px solid #cdd3d6;
      border-bottom: 1px solid #cdd3d6;
      border-radius: inherit;
      font: Bold 24px/32px var(--linq-font-secondary) !important;
      letter-spacing: 0px;
      color: var(--linq-color-primary-500);
      padding: 0.83rem;
      max-height: 27px;
      width: 250px;
      cursor: pointer;
    }
  }

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 100%;
    line-height: 1em;
    background-color: @white;
    border: 1px solid @border-grey;
    box-shadow: 0 1px 2px #00000014;

    &:focus {
      svg {
        transform: scale(1.2);
        transition: transform 200ms ease;
      }
    }

    .icon {
      margin: 0;
    }

    svg {
      color: @text-secondary;
      font-size: 32px;
      transition: transform 200ms ease;
    }

    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  .btn-disabled {
    svg {
      opacity: 0.8;
    }

    cursor: default !important;
  }
}
