@import 'Styles/variables';

.calendar-week-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: var(--linq-spacing-8);

  &.mobile {
    tr > td.no-items {
      padding: 25px !important;
      text-align: center !important;
    }
  }

  .ordering-rules {
    position: relative;
    margin-right: var(--linq-spacing-10);

    .close-icon {
      position: absolute;
      cursor: pointer;
      top: 0;
      right: calc(var(--linq-spacing-8) * -1);
      padding: 0;
    }
  }
}

.calendar-week__table {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  thead {
    tr {
      padding: 0;

      th {
        width: 20%;
        padding: 16px !important;
        font-weight: bold;
        font-size: 16px;
        text-align: center;
        background-color: @color-header;
        color: @white;
      }
    }
  }

  tbody {
    tr {
      padding: 0;

      & > td {
        border-top: unset;

        &.group {
          text-align: center;
          vertical-align: center;
          height: 32px;
          text-transform: uppercase;
          background-color: @table-secondary;
          padding: 8px !important;
        }

        &:not(.group):not(:last-child) {
          border-right: 1px solid @table-secondary;
        }

        &.content {
          padding: 0 !important;
          background-color: @white;
        }

        &.no-items {
          padding: 50px;
          text-align: center !important;
        }
      }
    }
  }
}
