@import 'Styles/variables';

.breadcrumb-container {
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 30px;

  svg {
    font-size: 10px;
    color: @text-secondary;
    margin-right: 10px;
  }

  button,
  .item {
    margin-right: 10px;

    span.MuiButton-label {
      text-transform: none;
    }

    &.link {
      color: @primary-blue;
      cursor: pointer;
    }
  }
}
