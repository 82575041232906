.weekday-picker-container {
  display: flex;

  button:nth-child(1) {
    margin-right: auto;
  }

  span {
    flex: 1;
  }

  button:nth-child(2) {
    margin-left: auto;
  }

  button:disabled {
    visibility: hidden;
  }
}
