@import 'Styles/variables';

.meal-type-picker-container-mobile {
  margin: 10px auto 20px auto;
}

.meal-type-picker-container {
  float: left;
  margin-left: 5px;
}

.meal-type-picker-container,
.meal-type-picker-container-mobile {
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  text-align: center;
  width: 300px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 48px;
    text-transform: uppercase;
    font: 16px/24px 'Source Sans Pro';
    letter-spacing: 0px;
    color: @text-primary;
    opacity: 1;
    border-bottom: 2px solid @border-grey;
    cursor: pointer;

    &:focus,
    &:active {
      color: @text-dark;
      border-bottom: 4px solid @text-dark;

      &.selected {
        border-bottom: 4px solid @primary-blue;
      }
    }

    &.selected {
      color: @primary-blue;
      border-bottom: 2px solid @primary-blue;
    }
  }
}

.menu-type-select {
  min-width: 300px;

  .text-input .MuiInputBase-root {
    background-color: @white !important;
    border: 1px solid @border-grey !important;
  }

  .Mui-focused.MuiFilledInput-root {
    border-color: transparent !important;
  }
}
