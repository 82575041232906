@import 'Styles/variables';

.nutrition-facts-popup-mobile {
  padding: 10px;
}

.nutrition-facts-popup {
  .nutritional-link {
    padding-left: 25px;
  }

  .table-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    table {
      padding: 1rem;
      border-collapse: separate;
      font-family: 'Proxima-Nova';

      .MuiTableCell-root {
        font-family: 'Proxima-Nova';
      }
    }
  }
}

.nutrition-facts-popup,
.nutrition-facts-popup-mobile {
  font-size: 14px;
  color: @product-name-dark !important;

  .product-category {
    text-transform: uppercase;
    text-align: left;
    font: 14px/24px Source Sans Pro;
    letter-spacing: 0px;
    color: var(--linq-color-gray-500);
    opacity: 1;
  }

  .name {
    text-align: left;
    font: Bold 18px/24px Proxima-Nova;
    letter-spacing: 0px;
    opacity: 1;
    color: @product-name-dark;
    margin-bottom: 3px;
  }

  .nutrients-header {
    font: Bold 18px/24px 'Proxima-Nova';
    letter-spacing: 0px;
    color: #2d3541;
    opacity: 1;
  }

  .product-ingredients {
    margin-right: 4px;
    text-align: left;
    font: 13px/24px 'Source Sans Pro';
    letter-spacing: 0px;
    color: @dark-grey;
    text-transform: uppercase;
    opacity: 1;
  }

  .price {
    text-align: left;
    font: 18px/24px 'Proxima-Nova';
    letter-spacing: 0px;
    color: @dark-grey;
    opacity: 1;
  }

  .description {
    text-align: left;
    font: 16px/20px 'Source Sans Pro';
    letter-spacing: 0.25px;
    color: #00000099;
    opacity: 1;
    margin: 8px 0px 16px 0px;
  }

  .table-wrapper {
    color: @product-name-dark !important;
    background: @table-secondary;
    border-radius: 0px 4px 4px 0px;
    opacity: 1;
    display: table;

    td {
      padding: 2px 6px;
      display: table-cell;

      &.label {
        font-weight: bold;

        p {
          font-weight: normal;
          margin: 0;
        }

        &.secondary {
          font-weight: normal;
          padding-left: 15px !important;
        }

        .trans-fat-label {
          font-style: italic;
        }
      }

      &.value {
        text-align: right !important;

        &.bolded {
          font-weight: bold;
        }

        &.calories {
          font-size: 44px;
        }
      }
    }
  }

  .no-border {
    border-top: 0 !important;
    font: 17px Source Sans Pro;
    letter-spacing: 0px;
    color: @dark-grey;
    opacity: 1;
  }

  .thick-border {
    border-top: 4px solid @product-name-dark !important;
  }

  .thick-border-bottom {
    border-bottom: 4px solid @product-name-dark !important;
  }

  .nutritional-link {
    font: 16px/24px Proxima-Nova;
    letter-spacing: 0px;
    color: @href;
    opacity: 1;
    cursor: pointer;
    margin-bottom: 5px;
  }
}

.popup {
  padding: 1px !important;
}

.nutrition-facts__additional-info {
  font: 13px/18px Source Sans Pro;
  color: var(--linq-color-gray-500);
  opacity: 1;
  margin: 8px 0px 20px 0px;
}
