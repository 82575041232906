.clear-btn-container {
  display: flex;
  padding-top: var(--linq-spacing-8);
}

.clear-min {
  margin-right: var(--linq-spacing-4) !important;
}

.max-range {
  padding-top: var(--linq-spacing-4);
}
