.incremental-number-input {
  display: flex;
  align-items: center;
}

.incremental-number-input__minus,
.incremental-number-input__add {
  border: 1px solid var(--linq-color-gray-500);
  border-radius: 50%;
  cursor: pointer;
  margin: 0 var(--linq-spacing-2);
}

.incremental-number-input__icon-btn.MuiIconButton-root {
  width: 48px;
  height: 48px;
}

.incremental-number-input__text-field.MuiFormControl-root.MuiTextField-root {
  margin-bottom: 0;
}

.incremental-number-input__text-field {
  .MuiInputBase-input.MuiOutlinedInput-input {
    text-align: center;
  }
}

.incremental-number-input__icon-btn:disabled {
  svg {
    border-color: rgba(0, 0, 0, 0.26);
  }
}
