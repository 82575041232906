@import 'Styles/variables';

.order-confirmation__balance-sub-header {
  display: flex;
  justify-content: space-between;
  margin: var(--linq-spacing-6) var(--linq-spacing-6) var(--linq-spacing-8) var(--linq-spacing-6);

  h2,
  h3 {
    margin: 0;
    align-self: center;
  }
}

.order-confirmation__sub-title {
  display: flex;
  justify-content: space-between;
  margin-top: var(--linq-spacing-6);
  h3 {
    margin: 0px !important;
  }
}

.order-Confirmation__total {
  padding-left: var(--linq-spacing-8);
}

.order-confirmation__date-list {
  margin-top: var(--linq-spacing-8);
}

.order-confirmation__menu-pickup-location-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--linq-spacing-4);
  margin-top: var(--linq-spacing-4);
}

.order-confirmation__name-price-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--linq-spacing-4);
}

.order-confirmation__main-content,
.order-confirmation__main-content-mobile {
  padding: 0px var(--linq-spacing-6);
  display: flex;
  justify-content: center;

  .linq-card__h6 {
    font-size: var(--linq-font-size-5) !important;
    margin: 0px;
  }

  h4 {
    margin: 0px;
  }

  .fa-3x {
    font-size: 1.8rem !important;
  }
}

.order-confirmation__add-to-balance {
  text-align: right;
  margin-top: var(--linq-spacing-4);
}
