@import 'Styles/variables';

.district-report-container {
  position: relative;
  width: 11in;
  min-height: 8.5in;
  margin: auto;
  background-color: @white;

  .pickup-location-container {
    padding: 0 20px;
    break-inside: avoid;
    background-color: @white;

    table {
      width: 100%;
      border-spacing: 0;
      border-collapse: collapse;

      thead {
        display: table-header-group;

        tr {
          break-inside: avoid;
          break-after: auto;

          th {
            &.pickup-location-name {
              font-size: 20px;
              padding: 20px 0 20px 0;
              text-align: left;
              font-weight: bold;
            }

            &.date {
              font-size: 16px;
              padding: 20px 20px 20px 0;
              text-align: right;
              font-weight: normal;
            }

            &.student-name {
              width: 150px;
              min-width: 150px;
              text-align: left;
              padding-left: 8px;
            }

            &.allergens {
              width: 100px;
              min-width: 100px;
              text-align: left;
              padding-left: 8px;
            }

            &.order-details {
              text-align: left;
              padding-left: 8px;
              min-width: 100px;
              width: 50%;
            }
            &.bulk-order {
              width: 38%;
              text-align: left;
              padding-left: 8px;
            }
            &.menu-type {
              width: 25%;
              text-align: left;
              padding-left: 8px;
            }
            &.ordered {
              width: 30%;
              text-align: center;
              padding-left: 8px;
            }

            &.status {
              width: 55px;
            }

            &.barcode {
              width: 222px;
            }
          }
        }
      }

      td {
        padding: 4px 8px;
        text-align: left;
        vertical-align: top;

        &.student-name {
          font-size: 16px;

          .student-id {
            font-size: 12px;
          }
        }

        &.status {
          text-align: center;
        }

        &.barcode {
          text-align: center;
          svg {
            margin-top: 4px;
          }
        }
      }
    }
  }
}

.print-report-comments-row,
.print-report__header-row {
  border-bottom: 1px solid #999999;
}

.print-report__row_comment {
  height: 125px;
}

.print-report-comments {
  position: absolute;
  transform: translate(8px, -25px);
}

.print-report__bulk-order-table {
  margin-top: var(--linq-spacing-6);
}
