.transaction-history {
  margin: var(--linq-spacing-4);
}

.transaction-history__cancel-btn {
  color: var(--linq-color-gray-900) !important;
}

.transaction-history__items {
  text-overflow: initial !important;
  white-space: break-spaces !important;
}
