.button-link {
  text-align: center;
}

.button-link.MuiButton-fullWidth {
  flex: 1;
}

.btn-link-disabled {
  cursor: initial !important;
  opacity: 0.5;
}
