@import 'Styles/variables';

.shop-container {
  padding: var(--linq-spacing-4);
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .page-content-wrapper {
    padding: var(--linq-spacing-3) var(--linq-spacing-8);
  }

  .sub-header {
    .balance {
      text-align: left;
      font: 1.7rem Proxima-Nova;
      letter-spacing: 0px;
      color: #6c7f9a;

      &.mobile {
        font-size: 24px;
      }
    }

    .divider {
      text-align: left;
      font: 1.7rem Proxima-Nova;
      letter-spacing: 0;
      color: #6c7f9a;
      padding-right: 10px;
      opacity: 1;

      &.mobile {
        display: none;
      }
    }

    .ordering-rules {
      color: @text-primary;
      font-size: 20px;
      flex: 1;
      text-align: right;
      padding-left: 1.5rem;
    }

    &--mobile {
      .ordering-rules {
        text-align: center;
      }
    }
  }

  .menu-sub-header {
    padding-left: var(--linq-spacing-6);
  }

  .shop-options-container {
    display: flex;
    &.mobile {
      display: block;
    }
    .menu-type-select {
      width: 50%;
      @media (max-width: 785px) {
        width: 100%;
      }
    }
  }

  .location-dropdown {
    margin-right: var(--linq-spacing-3);
    min-width: 300px;
    width: 50%;
    &.mobile {
      width: 100%;
    }
  }

  .shop-calendar-container {
    display: flex;
    width: 100%;
    height: 3.5em;

    .date-range-nav-container {
      margin-left: auto;
    }
  }

  .shop-calendar-container-mobile {
    flex-direction: column-reverse;
  }
}
