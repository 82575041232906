@import 'Styles/variables';

.ordering-rules-sidebar-container {
  .offer-only-a-la-carte {
    margin-bottom: 32px;

    .MuiSwitch-colorPrimary.Mui-checked {
      color: @button-primary;
    }
  }

  .ordering-rules-sidebar-content {
    overflow-x: hidden;
  }

  .date-picker-container {
    display: flex;
  }

  .MuiDivider-root {
    margin: var(--linq-spacing-8);
    min-height: 2px;
  }
}

.ordering-rules-container {
  .content {
    padding: 0 20px 0;

    p {
      font-size: 16px;
      margin-top: 0px;
    }
  }
}
.ordering-rules__section-header {
  text-transform: uppercase;
  color: var(--linq-color-primary-500);
  margin-top: 0;
}
.ordering-rules__section-option {
  display: flex;
  max-height: 3.5rem;
}
.ordering-rules__section-range {
  margin-right: var(--linq-spacing-4) !important;
}

.ordering-rules__section-cut-off {
  color: var(--linq-color-primary-500);
  font-weight: bold;
}

.ordering-rules__rules {
  display: flex;
  justify-content: space-between;
}

.ordering-rules__setting {
  flex: 1;
}

.linq-drawer-footer .oo-drawer-main-footer {
  .linq-ml-2 {
    margin-left: var(--linq-spacing-2);
  }
}

.timepicker-container {
  .MuiFormControl-root {
    min-width: 32.8%;
  }
}
