@import 'Styles/variables';

.parent-newsfeed-card-container {
  background: @white;
  margin: var(--linq-spacing-3);
  width: 462px;
  min-height: 416px;
  max-height: 472px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 3px #00000033;
  border-radius: 4px;
  opacity: 1;
  overflow-y: scroll !important;

  h3 {
    text-align: center;
  }

  .newsfeed-list {
    padding: 0;
    margin-left: var(--linq-spacing-6);
    margin-right: var(--linq-spacing-6);
  }

  .newsfeed-item {
    flex-direction: column;
    align-items: flex-start;

    .title,
    .date,
    .district,
    .content {
      line-height: 18px;
    }

    .content {
      margin-top: var(--linq-spacing-2);
    }
  }

  .newsfeed-item.linq-button,
  .mdc-button,
  .mat-button-base:not(.mat-icon-button),
  .MuiButtonBase-root:not(.MuiIconButton-root):not(.MuiChip-clickable) {
    text-transform: none;
    padding: var(--linq-spacing-2) 0;
  }

  .placeholder {
    margin: auto;
    padding: 0 var(--linq-spacing-6);
  }
}

.parent-newsfeed-card-container.mobile {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.parent-newsfeed__divider.MuiDivider-root {
  margin-left: var(--linq-spacing-6);
  margin-right: var(--linq-spacing-6);
}
