@import 'Styles/variables';

.tooltip {
  font-weight: bold;
}

.text-input {
  height: initial !important;
}

.preview-image {
  max-width: 100px;
  display: block;
}

.a-la-carte-container {
  .product-image {
    width: 100px;
    margin-bottom: 0;
    margin-right: 10px;
  }

  .a-la-carte__content {
    padding: 48px 20px 0;

    h2 {
      margin: 0;
      color: var(--linq-color-primary-500);
    }

    p {
      font-size: 16px;
      margin-top: 8px;
    }

    .oo-row {
      align-items: start;

      .settings {
        display: flex;
        flex: 1;
        flex-wrap: wrap;
      }

      .setting {
        width: 20%;

        .setting-header {
          text-transform: uppercase;
          color: #56667d;
          font-size: 14px;
        }

        .setting-value {
          font-size: 16px;
          margin-bottom: 8px;
        }
      }
      @media (max-width: 785px) {
        .setting {
          width: 100%;
          padding-left: 10px;
        }
      }
      .meal-type-availability {
        margin-right: 20px;
        width: 100px;
        text-align: center;
        color: #94a2b6;
      }
      .meal-type-availability .meal-type {
        display: inline;
        margin: 5px;
      }
      .meal-type-availability .meal-type svg {
        width: 0.8em !important;
      }
      .card-right-side {
        align-items: center;
        margin-right: 20px;
        display: flex;
        flex-flow: row;
        .setting {
          width: 215px;
        }
      }
      .edit-setting {
        margin-left: auto;
        margin-right: 15px;

        svg {
          font-size: 28px;
          color: #94a2b6;
          cursor: pointer;
        }
      }
    }
  }
  .a-la-carte-card {
    margin-bottom: 24.5px !important;
  }
}

.product-details {
  display: flex;
  width: 25rem;
}

.product-details__image {
  width: 10rem;
}

.product-details__content {
  flex: 1;
  margin-right: var(--linq-spacing-2);
}

.a-la-carte-container-mobile {
  .a-la-carte-card .oo-row {
    flex-direction: column;
    align-items: flex-start;

    .category {
      margin-bottom: 2rem;
      width: 100%;
    }

    .setting {
      flex: auto;
      margin: 0 1rem 1.5rem 0;
      width: auto;
    }
  }
}

.a-la-carte-item-card {
  margin-bottom: 25px !important;
  padding: var(--linq-spacing-8);
}
.add-button {
  width: 100%;
}

.product-image-placeholder {
  float: left;
  width: 100px;
  margin-right: 10px;
  background: #eee;
  padding: var(--linq-spacing-10) var(--linq-spacing-6);
  color: #ccc;
  display: flex;
  justify-content: center;
  svg {
    transform: scale(2);
  }
}
.delete-item {
  color: #94a2b6;
  position: absolute;
  right: 10px;
  top: 8px;
  cursor: pointer;
}
