@import 'Styles/variables';

.main-footer {
  display: flex;
  padding: 10px;
  height: 76px;

  //button reset
  button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;
    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;
    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
    text-transform: uppercase;
  }

  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: @color-primary;
  .divider {
    color: @text-dark;
    padding: 0 6px;
  }
}
