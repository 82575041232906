.edit-newsfeed-container {
  margin: var(--linq-spacing-4);
  padding: var(--linq-spacing-4);

  .card-title {
    margin-bottom: var(--linq-spacing-8);
  }

  .date-range-field {
    display: flex;
    flex-wrap: wrap;
  }

  .title-field {
    width: 100%;
    max-width: 352px;
  }
}
