@import 'Styles/variables';

.import-data-container {
  .body {
    h2 {
      margin: 0;
      color: #212121;
      font-size: 18px;
    }

    .import-header {
      display: flex;
      // justify-content: space-between;
      margin-bottom: 15px;
      div {
        flex: 1;
      }
      div:last-of-type {
        text-align: right;
      }
      a {
        color: @primary-blue;
        cursor: pointer;
        font-weight: 600;
        margin-left: 15px;
      }
    }
    @media (max-width: 785px) {
      h2 {
        text-align: center;
      }
      .import-header {
        display: block;
        a {
          display: block;
          width: 100%;
          padding: 15px 0;
          color: #fff;
          background: @primary-blue;
          border-radius: 4px;
          margin: 5px auto;
          cursor: pointer;
        }
        a:last-of-type {
          margin: 0 auto;
        }
        div:first-of-type {
          text-align: center;
        }
        div:last-of-type {
          text-align: center;
        }
      }
    }

    .import-data-card {
      flex: 1;
      height: 100%;
      width: 100%;

      .invalid-filetype {
        text-align: center;
        color: @alert-error;
      }
      .valid-filetype {
        text-align: center;
        color: @alert-success;
      }
    }
    .import-log-container {
      margin-top: 20px;
      svg {
        vertical-align: -0.25em;
      }
      .check-icon {
        color: @alert-success;
      }
      .error-icon {
        color: @alert-error;
      }
      .warning-icon {
        color: @alert-warning;
      }
      .react-table-container {
        width: 100%;
        table thead th {
          display: flex;
        }
        table tbody td div {
          justify-content: center;
        }
        table tbody td:first-of-type svg {
          width: 30px;
          height: 30px;
        }
      }

      .import-log-card-empty {
        text-align: center;
        width: 100%;
      }
      .import-log-card-updates {
        text-align: center;
        width: 100%;
        margin-bottom: var(--linq-spacing-6);
        button {
          cursor: pointer;
          color: @primary-blue;
        }
      }
    }
  }
}

.import-data__upload-icon {
  margin: 24px;
  font-size: 42px;
  color: #919eb7;
}
