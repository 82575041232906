.page-footer {
  display: flex;
  position: fixed;
  width: 100%;
  bottom: 0;
  background: #ffffff;
  justify-content: space-between;
  box-shadow: 0 0px 3px rgba(var(--linq-color-gray-rgb-800), 0.4);
  padding: var(--linq-spacing-3) 0;
  z-index: 1;

  .left-content {
    display: flex;
    justify-content: flex-start;
    flex: 1;
    margin-left: var(--linq-spacing-4);
  }

  .right-content {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    margin-right: var(--linq-spacing-4);
  }
}
