@import 'Styles/variables';

.linq-button-group {
  border: 2px solid var(--linq-color-primary-500);
  .button-group-icon {
    align-items: center;
    svg {
      margin-left: var(--linq-spacing-1);
      margin-bottom: 1px;
    }
  }
}

div.linq-button-group {
  button.MuiButtonBase-root {
    margin-left: 0;
  }

  button.MuiButtonBase-root:not(:first-child) {
    border-left: 2px solid var(--linq-color-primary-500);
  }

  .MuiButtonBase-root:not(.MuiIconButton-root):not(.MuiChip-clickable) {
    color: var(--linq-color-primary-500);
    border-radius: 0;
  }

  .MuiButtonBase-root:not(.MuiIconButton-root):not(.MuiChip-clickable).focused {
    color: var(--linq-color-primary-contrast-500);
    background: var(--linq-color-primary-500);
  }
}
