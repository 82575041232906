.multi-action-card {
  .linq-button,
  .mdc-button,
  .mat-button-base,
  .MuiButtonBase-root:not(.MuiIconButton-root):not(.MuiChip-clickable) {
    &:disabled {
      border-color: var(--linq-color-gray-400);
      color: var(--linq-color-gray-400);
    }
  }

  .submit-button {
    :hover {
      color: var(--linq-color-primary-200);
    }
    min-width: 90px;
    min-height: 36px;
  }
}
