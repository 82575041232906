@import 'Styles/variables';

.district-dashboard-container {
  display: flex;
  flex-wrap: wrap;
  padding: 45px 20px;
  max-width: 1400px;
  margin: auto;

  &.contains-limited-set {
    max-width: 1024px;
  }

  .filler-space {
    margin: 0 1rem 1rem 0;
    flex: 1;
    width: 100%;
    min-width: 300px;
  }
}

.district-dashboard__card {
  flex: 1;
  width: 100%;
  min-width: 300px;
  max-width: 300px;
  height: 209px;
  font-size: 18px;
  color: var(--linq-color-secondary-600);
  text-transform: uppercase;
  font-weight: bold;

  span {
    display: block;
    font-size: var(--linq-font-size-6);
    font-weight: bold;
    text-align: center;
  }

  svg {
    font-size: 3.5rem;
    color: @color-primary;
  }
}

.district-dashboard__card-link {
  text-decoration: none;
  margin: 0 1rem 1rem 0;
}

.district-dashboard__card-area.MuiCardActionArea-root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  border-radius: 0 !important;
}

.district-dashboard__card-text {
  margin-top: 1.5rem;
}
