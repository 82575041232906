@import 'Styles/variables';

.table-container {
  margin: var(--linq-spacing-6);
}

.card-container {
  display: grid;
  grid-template-columns: 60% 40%;
}

.close-school-year-card {
  margin-right: var(--linq-spacing-6) !important;
}

svg {
  vertical-align: -0.25em;
}
.check-icon {
  color: @alert-success;
}
.error-icon {
  color: @alert-error;
}

.linq-table tbody tr td {
  text-overflow: unset !important;
  white-space: unset !important;
  word-wrap: break-word;
}
