@import 'Styles/variables';

.menu-item-container {
  display: flex;
  flex-direction: column;
  min-height: 151px;
  margin: 18px 18px 0;
  transition: transform 200ms ease;

  .body {
    width: 100%;
  }

  &:last-child {
    margin-bottom: 18px;
  }

  &:focus {
    transform: scale(1.1);
    transition: transform 200ms ease;
    box-shadow: 0 0 7px 4px #d3d6d9;
  }
}
.menu-item-container,
.menu-item-container-mobile {
  padding: 0;

  .image {
    height: 125px;
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px 4px 0 0;
    background-color: @border-grey;
    border: 1px solid @border-grey;
  }

  .body {
    border: 1px solid @border-grey;
    border-top: unset;
    border-radius: 0 0 4px 4px;
    display: flex;
    height: 100%;
    flex-direction: column;

    .name {
      color: @color-primary;
      flex: 1 1 60px;
      font-size: 16px;
      font-weight: 600;
      margin: 1rem 1rem 0.5rem 1rem;
      text-align: left;
      overflow: hidden;
    }

    .price {
      font-size: 14px;
    }

    .oo-row {
      align-items: center;
      padding: 6px 14px;
    }

    .open {
      font-size: 20px;
      color: @color-primary;
      height: 24px;

      .icon {
        margin-right: 0;
      }
    }
  }

  .cart-item-count {
    background-color: @color-primary;
    color: @white;
    width: 20px;
    border-radius: 50%;
    height: 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  .cart-status-icon {
    width: 20px;
    height: 20px;
  }
}

.menu-item-container-mobile {
  display: inline-block;
  margin: 8px;
  flex: 1 1 100%;

  .body {
    height: unset;
  }

  .image {
    height: 110px;
    width: 100%;
  }
}

.menu-item-container:hover {
  cursor: pointer;
}
