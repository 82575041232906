@import 'Styles/variables';

.add-student-container {
  margin: var(--linq-spacing-3);
  min-height: 416px;
}

.add-student-container-mobile {
  height: 416px;
}

.add-student-modal-close {
  float: right;
}

.add-student-container,
.add-student-container-mobile {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 462px;
  background: @white;

  div {
    padding-top: 10px;
    text-align: center;
    font: 14px/24px 'Source Sans Pro';
    letter-spacing: 0px;
    color: #1975dd;
    opacity: 1;
  }
}

.add-student-modal-container {
  color: @text-secondary;

  .error-message {
    margin: 20px 0;
    text-align: center;
    color: @alert-error;
  }

  .selection {
    background-color: @background-color !important;
    height: 56px;
    font: 16px/24px Source Sans Pro !important;
  }

  input {
    font: 16px/24px Source Sans Pro !important;
  }

  input::placeholder {
    color: @text-secondary !important;
    opacity: 1; /* Firefox */
  }

  input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: @text-secondary !important;
  }

  input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: @text-secondary !important;
  }

  .default.text {
    color: @text-secondary !important;
    font: 16px/24px Source Sans Pro !important;
    letter-spacing: 0px !important;
    opacity: 1 !important;
  }

  .dropdown.icon {
    font-size: 18px !important;
  }
}

.add-student-modal__plus.MuiSvgIcon-root {
  font-size: 4rem;
}
