.no-data-row {
  height: 48px;
}

.no-data-msg {
  z-index: 1;
  text-align: center;
  margin: auto;
}

.drag-handle-button-inactive {
  background-color: transparent !important;
}

.drag-handle {
  color: var(--linq-color-primary-500);
}

.table-drag-layer {
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  width: '100%';
  height: '100%';
}

.drag-preview-row {
  display: flex !important;
}
